import * as Yup from 'yup';

const checkValidEmailVariables = Yup.string()
  .test('containsLien', 'La variable [lien] est obligatoire', (value) => {
    if (!value) return true;
    return value.includes('[lien]');
  })
  .test(
    'validPlaceholders',
    'Des variables invalides ont été trouvées, seules les variables suivantes sont autorisées : [nom], [prenom], [lien], [contact], [signature], [metiers]',
    (value) => {
      const validVariables = [
        '[nom]',
        '[prenom]',
        '[lien]',
        '[contact]',
        '[signature]',
        '[metiers]',
      ];
      if (!value) return true;

      const matches = value.match(/\[.*?\]/g);
      if (!matches) return true;

      const invalidVariables = matches.filter((match) => {
        if (match.startsWith('[sujet: ')) {
          return false; // Allow any [sujet: ...] variable
        }
        return !validVariables.includes(match);
      });
      return invalidVariables.length === 0;
    },
  )
  .test(
    'validSignature',
    'La phrase "Message transmis via Bilik" doit être présente dans le texte.',
    (value) => {
      if (!value) true;
      return value.includes('Message transmis via Bilik');
    },
  );

export default (): Yup.ObjectSchema =>
  Yup.object().shape({
    customReviewInvitationEmail: checkValidEmailVariables,
    customReviewInvitationDunningEmail: checkValidEmailVariables,
  });
