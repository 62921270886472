import {
  Button,
  Dimmer,
  Divider,
  Dropdown,
  Icon,
  Label,
  Loader,
  Popup,
  Table,
} from 'semantic-ui-react';
import React, { Fragment, FunctionComponent, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { SolicitationsTableQuery } from 'generated/graphql';
import { formatDate, formatDateTime } from 'utils/locale';
import { GloballyBlocked } from 'components/globally-blocked';
import Raw from 'components/raw/raw';
import ConfirmModal from 'components/confirm-modal/confirm-modal';
import { Unpacked } from 'utils/unpacked';
import { ProBouncedSolicitation } from '../pro-bounced-solicitation';
import { useCurrentBilikZones } from 'context/current-bilik-zones-context';
import ProFullName from 'components/pro-full-name/pro-full-name';
import SolicitationAutorespondToggle from '../solicitation-autorespond-toggle/solicitation-autorespond-toggle';
import { PiBouncedSolicitation } from '../pi-bounced-solicitation/pi-bounced-solicitation';
import { formatTelephone } from 'utils';

type SolicitationTableProps = {
  loading: boolean;
  onClickEmail: (email?: string) => void;
  onClickIp: (ip?: string) => void;
  onClickProPresentationId: (proPresentationId?: number) => void;
  solicitations: SolicitationsTableQuery['solicitation'];
  onClickSortByCreateDate: () => void;
  globallyBlockedEntities: {
    identifier: string;
  }[];
  onClickOpenProReview: (
    solicitation?: Unpacked<SolicitationsTableQuery['solicitation']>,
  ) => void;
  orderBy;
  refetch: () => void;
  isInProViewForm: boolean;
  resendMailToPro: (uuid: string) => Promise<void>;
};

const SolicitationsTableView: FunctionComponent<SolicitationTableProps> = ({
  loading,
  onClickEmail,
  onClickIp,
  onClickProPresentationId,
  onClickSortByCreateDate,
  onClickOpenProReview,
  solicitations,
  globallyBlockedEntities,
  orderBy,
  isInProViewForm,
  refetch,
  resendMailToPro,
}) => {
  const { currentBilikZones } = useCurrentBilikZones();

  const isGloballyBlocked = useCallback(
    (identifier: string): boolean => {
      return globallyBlockedEntities.some(
        (value) => value.identifier === identifier,
      );
    },
    [globallyBlockedEntities],
  );

  return (
    <Dimmer.Dimmable>
      <Dimmer inverted verticalAlign="top" active={loading}>
        <Loader style={{ marginTop: '20px' }} inline>
          Chargement...
        </Loader>
      </Dimmer>
      <Table striped celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              onClick={(): void => {
                onClickSortByCreateDate();
              }}
              sorted={orderBy.direction}
            >
              Date de la demande
            </Table.HeaderCell>
            <Table.HeaderCell className="not sortable">
              Particulier
            </Table.HeaderCell>
            {!isInProViewForm ? (
              <Table.HeaderCell className="not sortable">
                Professionnel
              </Table.HeaderCell>
            ) : null}
            <Table.HeaderCell className="not sortable">
              Message
            </Table.HeaderCell>
            <Table.HeaderCell className="not sortable">Avis</Table.HeaderCell>
            <Table.HeaderCell className="not sortable">
              Actions
            </Table.HeaderCell>
            <Table.HeaderCell className="not sortable">
              Relance
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {solicitations.map((solicitation) => (
            <Table.Row key={solicitation.id}>
              <Table.Cell>
                {solicitation.dateCreated ? (
                  <p>{formatDateTime(solicitation.dateCreated)}</p>
                ) : null}
              </Table.Cell>
              <Table.Cell verticalAlign="top">
                {`${solicitation.givenName} ${solicitation.familyName}`}
                <br />
                {formatTelephone(solicitation.telephone)}
                &nbsp;
                {isGloballyBlocked(`telephone:${solicitation.telephone}`) ? (
                  <Popup
                    position="top center"
                    trigger={<Icon color="red" name="dont" />}
                    content="Numero bloqué"
                  />
                ) : null}
                <br />
                <a
                  href="#/"
                  onClick={(): void => onClickEmail(solicitation.email)}
                >
                  {solicitation.email}
                </a>
                &nbsp;
                {isGloballyBlocked(`email:${solicitation.email}`) ? (
                  <Popup
                    position="top center"
                    trigger={<Icon color="red" name="dont" />}
                    content="Email bloqué"
                  />
                ) : null}
                <br />
                {solicitation.addressLocality ? (
                  <Fragment>
                    {solicitation.addressLocality}
                    <br />
                  </Fragment>
                ) : null}
                <a href="#/" onClick={(): void => onClickIp(solicitation.ip)}>
                  {solicitation.ip}
                </a>
                <br />
                <Popup
                  position="top center"
                  trigger={
                    <Link
                      to={`/timeline/${
                        solicitation.email !== 'NULL' &&
                        solicitation.telephone !== 'NULL'
                          ? solicitation.email
                          : solicitation.telephone
                      }`}
                      style={{
                        textDecoration: 'none',
                      }}
                    >
                      <Icon fitted name="time" />
                      <Icon fitted name="long arrow alternate down" />
                    </Link>
                  }
                  content="Timeline du particulier"
                />
                <Divider hidden />
                {solicitation.configuration?.isRedirectedToBilik ? (
                  <>
                    <Label color="grey">
                      Non transmise au pro car copier-coller
                    </Label>
                    <Divider hidden />
                  </>
                ) : null}
                {solicitation.lastResponseDate ? (
                  <>
                    <Popup
                      trigger={<Label color="orange">À relancé le pro</Label>}
                    >
                      Le {formatDateTime(solicitation.lastResponseDate)}
                    </Popup>
                  </>
                ) : null}
                {solicitation.bounceEntity &&
                solicitation.bounceEntity === 'pi' ? (
                  <>
                    <PiBouncedSolicitation
                      solicitation={solicitation}
                      resendMailToPro={resendMailToPro}
                    />
                    <br />
                  </>
                ) : null}
              </Table.Cell>
              {!isInProViewForm ? (
                <Table.Cell verticalAlign="top">
                  <a
                    href="#/"
                    onClick={(): void => {
                      onClickProPresentationId(
                        solicitation?.proView.proPresentation.id,
                      );
                    }}
                  >
                    <ProFullName
                      status={solicitation.proView.status}
                      name={solicitation.proView.proPresentation.name}
                      mainCityCode={
                        !currentBilikZones
                          ? solicitation.proView.bilikZone.mainCityCode
                          : undefined
                      }
                      mainTradeName={
                        solicitation.proView.proPresentation.mainTrade?.name
                      }
                    />
                  </a>
                  <br />
                  {solicitation.proView.proPeople.map((proPerson, index) => (
                    <Fragment key={`pro-person-${index}`}>
                      {proPerson.proPerson.givenName}{' '}
                      {proPerson.proPerson.familyName}
                      <br />
                    </Fragment>
                  ))}
                  <br />
                  {solicitation.bounceEntity &&
                  solicitation.bounceEntity === 'pro' ? (
                    <>
                      <ProBouncedSolicitation
                        solicitation={solicitation}
                        refetchSolicitations={refetch}
                        resendMailToPro={resendMailToPro}
                      />
                      <br />
                    </>
                  ) : null}

                  {solicitation.source === 'sms' && (
                    <>
                      <Label color="grey">Demande initiale par téléphone</Label>
                      <br />
                    </>
                  )}

                  {solicitation.configuration?.proHoliday ? (
                    <Popup
                      trigger={
                        <Label color="orange">
                          En congé lors de la demande
                        </Label>
                      }
                    >
                      Du{' '}
                      {formatDate(
                        solicitation.configuration.proHoliday?.startDate,
                      )}{' '}
                      au{' '}
                      {formatDate(
                        solicitation.configuration.proHoliday?.endDate,
                      )}
                    </Popup>
                  ) : null}
                </Table.Cell>
              ) : null}
              <Table.Cell verticalAlign="top">
                {solicitation.subject ? (
                  <p>
                    <b>Sujet : </b>
                    {solicitation.subject}
                  </p>
                ) : null}
                <p>
                  {solicitation.source === 'sms' && (
                    <b>Résumé de l&apos;appel : </b>
                  )}
                  <Raw>{solicitation.message}</Raw>
                </p>
                {solicitation.delay ? (
                  <p>
                    <strong>Délai :</strong> {solicitation.delay}
                  </p>
                ) : null}

                {solicitation.declinedBy ? (
                  <p>
                    {solicitation.declinedBy === 'pro' ? (
                      <strong>
                        Décliné par le professionnel :<br />
                      </strong>
                    ) : null}
                    {solicitation.declinedBy === 'pi' ? (
                      <strong>
                        Décliné par le particulier :<br />
                      </strong>
                    ) : null}
                    <Raw>{solicitation.declineReason}</Raw>
                  </p>
                ) : null}
              </Table.Cell>
              <Table.Cell>
                {solicitation.proReview ? (
                  <Popup
                    position="top center"
                    content="Voir l'avis"
                    trigger={
                      <Button
                        basic
                        icon
                        onClick={(): void => {
                          onClickOpenProReview(solicitation);
                        }}
                      >
                        <Icon name="comment" />
                      </Button>
                    }
                  />
                ) : null}
              </Table.Cell>
              {/* Actions */}
              <Table.Cell>
                {solicitation.bounceEntity !== 'pro' ? (
                  <Dropdown
                    icon="triangle down"
                    button
                    className="icon"
                    direction="left"
                  >
                    <Dropdown.Menu>
                      <>
                        {solicitation.source === 'web' && (
                          <ConfirmModal
                            onConfirm={() =>
                              solicitation.uuid
                                ? resendMailToPro(solicitation.uuid)
                                : undefined
                            }
                            trigger={
                              <Dropdown.Item
                                icon="mail"
                                text="Renvoyer le mail au pro"
                              />
                            }
                            content={
                              <p>
                                Vous vous apprêtez à renvoyer le mail que notre
                                plateforme à envoyer au professionnel, êtes-vous
                                sûr vouloir faire cela?(Attention les pièces
                                jointes ne peuvent pas être renvoyées)
                              </p>
                            }
                            header="Confirmation de l'envoi de la demande initiale au professionnel"
                          />
                        )}

                        <GloballyBlocked
                          triggerNotBlocked={
                            <Dropdown.Item
                              icon="ban"
                              text="Bloquer le particulier"
                            />
                          }
                          triggerBlocked={
                            <Dropdown.Item
                              icon="ban"
                              text="Débloquer le particulier"
                            />
                          }
                          telephone={solicitation.telephone}
                          email={solicitation.email}
                        />
                      </>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : null}
              </Table.Cell>
              <Table.Cell>
                <SolicitationAutorespondToggle id={solicitation.id} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Dimmer.Dimmable>
  );
};

export default SolicitationsTableView;
