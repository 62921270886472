import { apiClient } from 'axios-client';
import AccountFormModal from 'components/account-form-modal/account-form-modal';
import { AccountSearchFilter } from 'components/account-search-filter';
import ConfirmModal from 'components/confirm-modal/confirm-modal';
import FieldError from 'components/field-error/field-error';
import FormField from 'components/form-field/form-field';
import { getIn, useFormikContext } from 'formik';
import {
  useAccountsFieldCreateAccountMutation,
  useAccountsFieldGetAccountIdLazyQuery,
  useAccountsFieldGetLinkedAccountLazyQuery,
  useAccountsFieldLinkAccountMutation,
  useAccountsFieldQuery,
  useAccountsFieldUnlinkAllAccountMutation,
} from 'generated/graphql';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { equals } from 'remeda';
import {
  Button,
  Container,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownItemProps,
  Icon,
  Label,
  Loader,
  Message,
  Table,
} from 'semantic-ui-react';
import {
  addKeycloakUserGroup,
  createKeycloakUser,
  executeKeycloakEmailActionOnUser,
  getGroupId,
  getKeycloakUserIdByEmail,
  getUserCredentials,
  getUserFederatedIdentity,
  ToastError,
  ToastInfo,
  ToastSuccess,
  ToastWarn,
} from 'utils';
import AccountEmailSuggestions from './account-email-suggestions/account-email-suggestions';
import { AccountProViewDeleteConfirmModal } from './account-pro-view-delete-confirm-modal';

type AccountsFieldProps = {
  name: string;
  mainName: string;
  currentProViewId?: number;
  accountEmailSuggestions?: string[];
  toastContent?: string;
};

type ModalState = {
  open: boolean;
  id?: number;
};

const AccountsField: FunctionComponent<AccountsFieldProps> = ({
  name,
  mainName,
  currentProViewId,
  accountEmailSuggestions,
  toastContent,
}) => {
  const { values, touched, errors, setFieldValue, setFieldTouched } =
    useFormikContext();

  const { currentBilikPerson } = useCurrentBilikPerson();

  const [accountModal, setAccountModal] = useState<ModalState>({
    open: false,
  });

  const [
    accountProViewDeleteConfirmModal,
    setAccountProViewDeleteConfirmModal,
  ] = useState<ModalState>({
    open: false,
  });

  const accountValues = useMemo(() => getIn(values, name), [values, name]);
  const mainAccountValue = useMemo(
    () => getIn(values, mainName),
    [values, mainName],
  );

  const [accountIds, setAccountIds] = useState<number[]>([]);

  // On value change check if old ids are equals to new ids (to avoid refreshing)
  useEffect(() => {
    const newAccountIds = accountValues.map((proPerson) => proPerson.id).sort();

    if (!equals(newAccountIds, accountIds)) {
      setAccountIds(newAccountIds);
    }
  }, [accountValues]);

  const { data } = useAccountsFieldQuery({
    variables: {
      ids: accountValues,
    },
  });

  const filterProTestAccount = useCallback(
    (account) => !account.email.includes('+pro@bilik'),
    [],
  );

  const accounts = useMemo(
    () => data?.account.filter(filterProTestAccount),
    [data],
  );

  const selectMainAccountOptions: DropdownItemProps[] = useMemo(() => {
    if (accounts) {
      return accounts.map((account) => ({
        key: `select-main-acount-option-${account.id}`,
        value: account.id,
        text: account.email,
      }));
    }
    return [];
  }, [accounts]);

  // Filtering suggestions to exclude already added emails
  const filteredAccountEmailSuggestions = useMemo(() => {
    if (accounts && accountEmailSuggestions) {
      const accountEmails = accounts.map((account) => account.email);
      return accountEmailSuggestions.filter(
        (accountEmailSuggestion) =>
          !accountEmails.includes(accountEmailSuggestion),
      );
    }
    return [];
  }, [accounts, accountEmailSuggestions]);

  const [linkAccountMutation] = useAccountsFieldLinkAccountMutation();
  const [unlinkAllAccountMutation] = useAccountsFieldUnlinkAllAccountMutation();
  const [getAccountIdQuery] = useAccountsFieldGetAccountIdLazyQuery();
  const [getLinkedAccountQuery] = useAccountsFieldGetLinkedAccountLazyQuery();
  const [createAccountMutation] = useAccountsFieldCreateAccountMutation();

  const handleLinkProTestAccount = useCallback(
    async (accountEmail: string) => {
      if (!currentBilikPerson) return;

      const [identifier, domain] = currentBilikPerson.account.email.split('@');

      const data: {
        testAccountEmail: string;
        testAccountId: number | null;
        keycloakUserId: string | null;
      } = {
        testAccountEmail: `${identifier}+pro@${domain}`,
        testAccountId: null,
        keycloakUserId: null,
      };

      data.keycloakUserId = await getKeycloakUserIdByEmail(
        data.testAccountEmail,
      );

      const testAccountResult = await getAccountIdQuery({
        variables: { email: data.testAccountEmail },
      });

      data.testAccountId = testAccountResult.data?.account?.[0]?.id ?? null;

      // Create database account if not exist
      if (!data.testAccountId) {
        console.log('Create database account');
        const result = await createAccountMutation({
          variables: { email: data.testAccountEmail },
        });
        data.testAccountId = result.data?.insertAccountOne?.id ?? null;

        if (!data.testAccountId) {
          ToastError('Erreur', 'Erreur lors de la création du compte de test');
          throw new Error('No testAccountId');
        }
      }

      // Create keycloak account if not exist
      if (!data.keycloakUserId) {
        ToastInfo(
          'Compte de test introuvable',
          `Création d'un nouveau compte de test...`,
        );

        const groupId = await getGroupId('pro-people/pros');

        await createKeycloakUser({
          email: data.testAccountEmail,
          username: data.testAccountEmail,
          enabled: true,
          credentials: [
            {
              type: 'password',
              value: 'bilik123test',
              temporary: true,
            },
          ],
        });

        data.keycloakUserId = await getKeycloakUserIdByEmail(
          data.testAccountEmail,
        );

        if (!data.keycloakUserId || !groupId) {
          ToastError('Erreur', 'Impossible de configurer le compte de test');
          throw new Error('user or group not found');
        }

        await addKeycloakUserGroup(data.keycloakUserId, groupId);

        ToastWarn(
          'Compte de test créé',
          <>
            <p>
              Ton compte de test a été créé. Tu peux accéder à l&apos;espace pro
              avec les identifiants suivants :
            </p>
            <p>
              <u>Email</u> :<b> {data.testAccountEmail}</b>
              <br />
              <u>Mot de passe :</u>
              <b> bilik123test</b>
            </p>
            <p>
              Il te sera demandé de changer ton mot de passe lors de ta première
              connexion.
            </p>
          </>,
        );
      }

      await unlinkAllAccountMutation({
        variables: {
          email: data.testAccountEmail,
        },
      });

      const result = await getLinkedAccountQuery({
        variables: { email: accountEmail },
      });

      const accountProView = result.data?.accountProView ?? [];

      await linkAccountMutation({
        variables: {
          objects: accountProView.map((accountProView) => ({
            proViewId: accountProView.proViewId,
            // Type is broken here I dont know why, I have to force it
            accountId: data.testAccountId as number,
          })),
        },
      });

      ToastWarn(
        <>
          Compte de test configuré sur
          <br />
          {accountEmail}
        </>,
        <>
          <p>
            Connecte toi à l&apos;espace pro avec ton compte de test :{' '}
            <b>{data.testAccountEmail}</b>
          </p>
          <p>
            <u>Rappel</u> : Déconnecte toi de l&apos;admin en amont ou utilise
            un onglet de navigation privé.
          </p>
        </>,
      );
    },
    [currentBilikPerson],
  );

  if (!accounts) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  return (
    <>
      {accounts && accounts.length > 0 ? (
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Statut</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {accounts.map((account) => {
              return (
                <Table.Row key={`accounts-field-${account.id}`}>
                  <Table.Cell>{account.email}</Table.Cell>
                  <Table.Cell>
                    <KeycloakAccountStatus email={account.email} />
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown
                      text="Actions"
                      labeled
                      button
                      floating
                      className="icon"
                    >
                      <Dropdown.Menu>
                        <ConfirmModal
                          trigger={
                            <DropdownItem
                              icon="envelope"
                              text="Création de compte"
                            />
                          }
                          header="Confirmation"
                          content={
                            <p>
                              Cette action va déclencher un envoi d&apos;email.
                              Continuer ?
                            </p>
                          }
                          onConfirm={async (): Promise<void> => {
                            const keycloakUserId =
                              await getKeycloakUserIdByEmail(account.email);

                            if (!keycloakUserId) {
                              ToastError(
                                'Erreur',
                                'Impossible de créer le compte (utilisateur introuvable dans keycloak)',
                              );
                              throw new Error(
                                `Cannot find Keycloak User with email: ${account.email}`,
                              );
                            }

                            const credentials = await getUserCredentials(
                              keycloakUserId,
                            );

                            const isPasswordCreated = !!credentials.find(
                              (credential) => credential.type === 'password',
                            );

                            if (isPasswordCreated) {
                              ToastInfo(
                                'Compte déjà créé',
                                'Un mot de passe a déjà été créé pour ce compte. En cas de perte utiliser l\'option : "Mot de passe oublié ?"',
                              );
                              return;
                            }

                            await apiClient
                              .post('/form/account/create-email', {
                                email: account.email,
                              })
                              .catch((error) => {
                                ToastError(
                                  'Erreur',
                                  "Impossible d'envoyer le mail de création de compte",
                                );
                                throw error;
                              });

                            ToastSuccess(
                              'Succès',
                              'Email de création de compte envoyé',
                            );
                          }}
                        />
                        <Dropdown.Item
                          icon="sign-in"
                          text="Espace pro"
                          onClick={() =>
                            handleLinkProTestAccount(account.email)
                          }
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                    <Button
                      icon
                      negative
                      type="button"
                      basic
                      style={{ minWidth: '35px', minHeight: '32px' }}
                      onClick={(): void => {
                        setAccountProViewDeleteConfirmModal({
                          open: true,
                          id: account.id,
                        });
                      }}
                    >
                      <Icon name="trash alternate outline" />
                    </Button>
                    <Button
                      icon
                      primary
                      style={{ minWidth: '35px', maxHeight: '32px' }}
                      type="button"
                      onClick={(): void => {
                        setAccountModal({ open: true, id: account.id });
                      }}
                    >
                      <Icon name="pencil alternate" />
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      ) : (
        <Message>Aucun comptes</Message>
      )}
      <Container fluid textAlign="right">
        <AccountSearchFilter
          style={{ marginRight: '15px' }}
          placeholder="Email..."
          onResultSelect={(event, { result }): void => {
            // Add only if not already exist
            if (!accountValues.includes(result.value)) {
              setFieldTouched(name);
              setFieldValue(name, [...accountValues, result.value], true);
              ToastInfo('Accès ajouté', toastContent);
            } else {
              ToastWarn('Attention', 'Accès déjà présent sur la fiche');
            }
          }}
        />
        <Button
          positive
          icon
          type="button"
          style={{ minWidth: '45px', minHeight: '32px' }}
          onClick={(): void => {
            setAccountModal({ open: true });
          }}
        >
          <Icon name="plus" />
        </Button>
      </Container>
      <Container fluid textAlign="right">
        <AccountEmailSuggestions
          emailSuggestions={filteredAccountEmailSuggestions}
          onSuggestionAdded={(accountId): void => {
            // Add only if not already exist
            if (!accountValues.includes(accountId)) {
              setFieldTouched(name);
              setFieldValue(name, [...accountValues, accountId], true);

              ToastInfo('Accès ajouté', toastContent);
            } else {
              ToastWarn('Attention', 'Accès déjà présent sur la fiche');
            }
          }}
        />
      </Container>
      {errors[name] && touched[name] ? (
        <FieldError>{errors[name]}</FieldError>
      ) : null}
      <Divider hidden />
      <FormField
        type="select"
        name={mainName}
        required
        label="Email principal"
        helpText="Adresse email recevant tous les mails issus de notre plateforme (demande mail, notification d'un nouvel avis"
        placeholder="Sélectionnez le compte principal..."
        search
        options={selectMainAccountOptions}
        selection
      />
      <AccountFormModal
        open={accountModal.open}
        accountId={accountModal.id}
        onClose={(): void => {
          setAccountModal({ open: false });
        }}
        onAccountCreated={(accountId): void => {
          setFieldTouched(name);
          setFieldValue(name, [...accountValues, accountId], true);
          ToastInfo('Accès ajouté', toastContent);
        }}
      />
      {accountProViewDeleteConfirmModal.id ? (
        <AccountProViewDeleteConfirmModal
          open={accountProViewDeleteConfirmModal.open}
          onClose={(): void => {
            setAccountProViewDeleteConfirmModal({ open: false });
          }}
          onConfirm={(accountId): void => {
            setFieldTouched(name);
            setFieldValue(
              name,
              accountValues.filter(
                (accountValueId) => accountValueId !== accountId,
              ),
              true,
            );
            ToastInfo('Accès retiré', toastContent);

            // Remove from mainAccount if needed
            if (mainAccountValue === accountId) {
              setFieldTouched(mainName);
              setFieldValue(mainName, null, true);
            }
          }}
          accountId={accountProViewDeleteConfirmModal.id}
          currentProViewId={currentProViewId}
        />
      ) : null}
    </>
  );
};

interface KeycloakAccountStatusProps {
  email: string;
}

const KeycloakAccountStatus: FunctionComponent<KeycloakAccountStatusProps> = ({
  email,
}) => {
  const [loading, setLoading] = useState(false);
  const [isPasswordcreated, setIsPasswordCreated] = useState<boolean>(false);
  const [isLinkedToGoogle, setIsLinkedToGoogle] = useState<boolean>(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setLoading(true);
    getKeycloakUserIdByEmail(email)
      .then(async (user) => {
        if (!user) {
          setIsError(true);
          return;
        }
        const credentials = await getUserCredentials(user);
        setIsPasswordCreated(
          !!credentials.find((credential) => credential.type === 'password'),
        );
        const federatedIdentities = await getUserFederatedIdentity(user);
        setIsLinkedToGoogle(
          !!federatedIdentities.find(
            (federatedIdentity) =>
              federatedIdentity.identityProvider === 'google',
          ),
        );
      })
      .finally(() => setLoading(false));
  }, [email]);

  if (isError) {
    return <Label color="red">Introuvable dans Keycloak</Label>;
  }

  if (loading)
    return (
      <>
        <Loader inline size="mini" active />
      </>
    );

  return (
    <>
      {isPasswordcreated && <Label color="green">Compte créé</Label>}
      {!isPasswordcreated && (
        <Label color="orange">En attente de création</Label>
      )}
      {isLinkedToGoogle && <Label color="green">Lien Google établi</Label>}
    </>
  );
};

export default AccountsField;
