import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { SortableImageItem } from '../sortable-image-item';
import {
  Active,
  closestCenter,
  CollisionDetection,
  DragOverlay,
  DndContext,
  DropAnimation,
  KeyboardSensor,
  KeyboardCoordinateGetter,
  Modifiers,
  MouseSensor,
  MeasuringConfiguration,
  PointerActivationConstraint,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import { createPortal } from 'react-dom';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  SortingStrategy,
  rectSortingStrategy,
  AnimateLayoutChanges,
  NewIndexGetter,
} from '@dnd-kit/sortable';
import { ImageItem } from '../image-item/image-item';
import { Button } from 'semantic-ui-react';
import ConfirmModal from 'components/confirm-modal/confirm-modal';
import styles from './image-sortable-list.module.css';

type ImageSortableListProps = {
  hashs: string[];
  openImageModal: (hash: string) => void;
  onDelete: (hash: string) => void;
  onSortEnd: (hashs: string[]) => void;
  onDeleteAll: () => void;
  activationConstraint?: PointerActivationConstraint;
  animateLayoutChanges?: AnimateLayoutChanges;
  adjustScale?: boolean;
  collisionDetection?: CollisionDetection;
  coordinateGetter?: KeyboardCoordinateGetter;
  dropAnimation?: DropAnimation | null;
  getNewIndex?: NewIndexGetter;
  measuring?: MeasuringConfiguration;
  modifiers?: Modifiers;
  reorderItems?: typeof arrayMove;
  strategy?: SortingStrategy;
  style?: React.CSSProperties;
  useDragOverlay?: boolean;
  getItemStyles?(args: {
    id: UniqueIdentifier;
    index: number;
    isSorting: boolean;
    isDragOverlay: boolean;
    overIndex: number;
    isDragging: boolean;
  }): React.CSSProperties;
  wrapperStyle?(args: {
    active: Pick<Active, 'id'> | null;
    index: number;
    isDragging: boolean;
    hash: string;
  }): React.CSSProperties;
};

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

export const ImageSortableList: FunctionComponent<ImageSortableListProps> = ({
  hashs,
  onDelete,
  openImageModal,
  onSortEnd,
  onDeleteAll,
  activationConstraint,
  animateLayoutChanges,
  adjustScale = false,
  collisionDetection = closestCenter,
  coordinateGetter = sortableKeyboardCoordinates,
  dropAnimation = dropAnimationConfig,
  getItemStyles = () => ({}),
  getNewIndex,
  measuring,
  modifiers,
  reorderItems = arrayMove,
  strategy = rectSortingStrategy,
  useDragOverlay = true,
  wrapperStyle = () => ({}),
}) => {
  const [active, setActive] = useState<string | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint,
    }),
    useSensor(TouchSensor, {
      activationConstraint,
    }),
    useSensor(KeyboardSensor, {
      // Disable smooth scrolling in Cypress automated tests
      scrollBehavior: 'Cypress' in window ? 'auto' : undefined,
      coordinateGetter,
    }),
  );

  const isFirstAnnouncement = useRef(true);
  const getIndex = (hash: string) => hashs.indexOf(hash);
  const activeIndex = active ? getIndex(active) : -1;

  useEffect(() => {
    if (!active) {
      isFirstAnnouncement.current = true;
    }
  }, [active]);

  return (
    <>
      <div className={styles.ButtonContainer}>
        {hashs.length > 0 && <DeleteAllButton onDelete={onDeleteAll} confirm />}
      </div>
      <div
        className={styles.GridContainer}
        style={
          {
            '--col-count': 4,
          } as React.CSSProperties
        }
      >
        <DndContext
          sensors={sensors}
          collisionDetection={collisionDetection}
          onDragStart={({ active }) => {
            if (!active) {
              return;
            }

            setActive(active.id.toString());
          }}
          onDragEnd={({ over }) => {
            setActive(null);

            if (over) {
              const overIndex = getIndex(over.id.toString());
              if (activeIndex !== overIndex) {
                const newHashs = reorderItems(hashs, activeIndex, overIndex);
                onSortEnd(newHashs);
              }
            }
          }}
          onDragCancel={() => setActive(null)}
          measuring={measuring}
          modifiers={modifiers}
        >
          <>
            <SortableContext items={hashs} strategy={strategy}>
              {hashs.map((hash, index) => (
                <SortableImageItem
                  hash={hash}
                  key={hash}
                  index={index}
                  openImageModal={openImageModal}
                  onDelete={onDelete}
                  dragging={active === hash}
                  style={getItemStyles}
                  wrapperStyle={wrapperStyle}
                  animateLayoutChanges={animateLayoutChanges}
                  useDragOverlay={useDragOverlay}
                  getNewIndex={getNewIndex}
                />
              ))}
            </SortableContext>

            {useDragOverlay
              ? createPortal(
                  <DragOverlay
                    adjustScale={adjustScale}
                    dropAnimation={dropAnimation}
                  >
                    {active ? (
                      <ImageItem
                        wrapperStyle={wrapperStyle({
                          active: { id: active },
                          index: activeIndex,
                          isDragging: true,
                          hash: hashs[activeIndex],
                        })}
                        style={getItemStyles({
                          id: hashs[active],
                          index: activeIndex,
                          isSorting: active !== null,
                          isDragging: true,
                          overIndex: -1,
                          isDragOverlay: true,
                        })}
                        dragOverlay
                        hash={active}
                        openImageModal={openImageModal}
                        onDelete={onDelete}
                      />
                    ) : null}
                  </DragOverlay>,
                  document.body,
                )
              : null}
          </>
        </DndContext>
      </div>
    </>
  );
};

type DeleteAllButtonProps = {
  onDelete: () => void;
  confirm?: boolean;
};

const DeleteAllButton: FunctionComponent<DeleteAllButtonProps> = ({
  onDelete,
  confirm,
}) => {
  const button = (
    <Button
      className="delete-all-button"
      negative
      icon
      type="button"
      onClick={!confirm ? onDelete : undefined}
    >
      Tous supprimer
    </Button>
  );

  if (!confirm) {
    return button;
  }

  return (
    <ConfirmModal
      trigger={button}
      header="Confirmation"
      content={<p>Voulez vous vraiment supprimer toutes les photos ?</p>}
      onConfirm={onDelete}
    />
  );
};
