import React, { FunctionComponent, useCallback, useState } from 'react';
import { apiClient } from 'axios-client';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { ToastWarn } from 'utils';

type ModerateProReviewReplyActionsProps = {
  proReviewId: number;
  proReviewStatus: string;
  reply?: string;
  replyStatus?: string;
  onModerateSuccess?: (type: 'validate' | 'refuse') => void;
  disabled?: boolean;
};

export const ModerateProReviewReplyActions: FunctionComponent<
  ModerateProReviewReplyActionsProps
> = ({
  proReviewId,
  reply,
  replyStatus,
  proReviewStatus,
  onModerateSuccess,
  disabled,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const moderateReply = useCallback(
    async (
      proReviewId: number,
      type: 'validate' | 'refuse',
      reply?: string,
    ) => {
      if (proReviewStatus !== 'ok') {
        ToastWarn(
          'Loupé',
          "Veuillez d'abord modérer l'avis avant de modérer la réponse (ceci afin d'être cohérent dans l'envoi des mails aux parties).",
        );
        return;
      }

      setIsLoading(true);
      await apiClient.post(`/form/review-pro/reply-moderate`, {
        proReviewId,
        type,
        reply,
      });
      setIsLoading(false);
      if (onModerateSuccess) onModerateSuccess(type);
    },
    [proReviewStatus, onModerateSuccess],
  );

  if (!replyStatus) return null;

  return (
    <>
      {replyStatus !== 'ok' ? (
        <Popup
          content={
            proReviewStatus === 'ok'
              ? 'Valider (déclenche des mails)'
              : "Veuillez d'abord modérer l'avis avant de modérer la réponse"
          }
          trigger={
            <span>
              <Button
                icon
                positive
                basic
                loading={isLoading}
                disabled={isLoading || disabled || proReviewStatus !== 'ok'}
                onClick={async () => {
                  await moderateReply(proReviewId, 'validate', reply);
                }}
              >
                <Icon name="checkmark" />
              </Button>
            </span>
          }
        />
      ) : null}
      {replyStatus !== 'ko' ? (
        <Popup
          content="Refuser (déclenche un mail vers le pro)"
          trigger={
            <Button
              icon
              negative
              basic
              loading={isLoading}
              disabled={isLoading || disabled || proReviewStatus !== 'ok'}
              onClick={async () =>
                await moderateReply(proReviewId, 'refuse', reply)
              }
            >
              <Icon name="remove" />
            </Button>
          }
        />
      ) : null}
    </>
  );
};
