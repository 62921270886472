import Client from 'client';
import gql from 'graphql-tag';
import { ToastError } from 'utils';
import { isValidFrTelephone } from 'utils/telephone';
import * as Yup from 'yup';

const isExistingSlug = async (slug?: string | null): Promise<boolean> => {
  return Client.query({
    query: gql`
      query ($slug: String!) {
        proPresentation(where: { slug: { _eq: $slug } }) {
          id
          slug
        }
      }
    `,
    variables: { slug: slug || '' },
  }).then(
    (response) =>
      response.data.proPresentation && response.data.proPresentation.length > 0,
  );
};

export const proFormUpdateValidationSchema = (
  currentSlug: string,
): Yup.ObjectSchema => {
  return Yup.object().shape({
    status: Yup.string().required('Status requis !'),
    trades: Yup.array()
      .of(Yup.number())
      .min(1, 'Au moins une catégorie requise'),
    telephones: Yup.array().when('status', {
      is: 'published',
      then: Yup.array()
        .of(
          Yup.object().shape({
            phoneNumber: Yup.string()
              .test(
                'is-valid',
                'Numéro de téléphone invalide !',
                isValidFrTelephone,
              )
              .required('Numéro de téléphone requis !'),
            status: Yup.string(),
          }),
        )
        .required()
        .min(1, 'Numéro de téléphone requis !'),
      otherwise: Yup.array().of(
        Yup.object().shape({
          phoneNumber: Yup.string()
            .test(
              'is-valid',
              'Numéro de téléphone invalide !',
              isValidFrTelephone,
            )
            .required('Numéro de téléphone requis !'),
        }),
      ),
    }),
    callTrackingTarget: Yup.object().shape({
      name: Yup.string().nullable().required('Nom de la cible requis !'),
      telephone: Yup.string()
        .nullable()
        .test(
          'is-valid',
          'Numéro de téléphone cible invalide !',
          isValidFrTelephone,
        )
        .required('Numéro de téléphone cible requis !'),
    }),
    callTrackingForward: Yup.object().shape({
      isActive: Yup.boolean(),
      name: Yup.string().when('isActive', {
        is: true,
        then: Yup.string().required('Nom de la cible de redirection requis !'),
      }),
      telephone: Yup.string().when('isActive', {
        is: true,
        then: Yup.string()
          .nullable()
          .test(
            'is-valid',
            'Numéro de téléphone de redirection invalide !',
            isValidFrTelephone,
          )
          .required('Numéro de téléphone de redirection requis !'),
      }),
      timeout: Yup.number().when('isActive', {
        is: true,
        then: Yup.number()
          .typeError('Nombre non valide !')
          .min(1, 'Nombre non valide !'),
      }),
    }),
    accounts: Yup.array().when('status', {
      is: 'published',
      then: Yup.array().of(Yup.number()).min(1, 'Minimum un compte requis !'),
    }),
    mainAccountId: Yup.number()
      .when('status', {
        is: 'published',
        then: Yup.number().required('Compte principal requis !'),
      })
      .nullable(),
    proPeople: Yup.array()
      .of(
        Yup.object().shape({
          position: Yup.number(),
          isVisible: Yup.boolean(),
          id: Yup.number(),
        }),
      )
      .min(1, 'Minimum un contact requis !'),
    colleagues: Yup.array().of(Yup.number()),
    isLocalBusiness: Yup.boolean(),
    openingHours: Yup.string().when('isLocalBusiness', {
      is: true,
      then: Yup.string().required("Horaires d'ouverture requises !"),
    }),
    isSolicitationDisabled: Yup.boolean(),
    cities: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().oneOf(['include', 'exclude']),
        id: Yup.number(),
      }),
    ),
    hasContact: Yup.boolean(),
    averageResponseDays: Yup.number()
      .typeError('Nombre non valide !')
      .min(0, 'Nombre non valide !')
      .required('Temps moyen de réponse requis !'),
    hasQuotation: Yup.boolean(),
    averageQuotationDays: Yup.number()
      .typeError('Nombre non valide !')
      .min(0, 'Nombre non valide !')
      .required('Temps moyen de réponse requis !'),
    averageProjectDays: Yup.number()
      .typeError('Nombre non valide !')
      .min(0, 'Nombre non valide !')
      .required('Temps moyen de réponse requis !'),
    proPresentation: Yup.object().shape({
      name: Yup.string().required('Nom de la fiche requis !'),
      slug: Yup.string()
        .required('Slug de la fiche requis !')
        .matches(/^[a-z0-9-]+$/, {
          excludeEmptyString: true,
          message: 'Format incorrect !',
        })
        .test('is-slug-uniq', 'Slug déjà existant !', async (slug) => {
          if (currentSlug !== slug) {
            return isExistingSlug(slug)
              .then((res) => res === false)
              .catch((error) => {
                ToastError(
                  'Erreur',
                  "Impossible de vérifier l'unicité du slug !",
                );
                throw error;
              });
          }
          return true;
        }),
      mainTradeId: Yup.number().nullable().required(),
      secondaryTradeId: Yup.number().nullable(),
      expertise1: Yup.string(),
      expertise11: Yup.string(),
      expertise12: Yup.string(),
      expertise13: Yup.string(),
      expertise14: Yup.string(),
      expertise15: Yup.string(),
      expertise16: Yup.string(),
      expertise2: Yup.string(),
      expertise21: Yup.string(),
      expertise22: Yup.string(),
      expertise23: Yup.string(),
      expertise24: Yup.string(),
      expertise25: Yup.string(),
      expertise26: Yup.string(),
      expertise3: Yup.string(),
      expertise31: Yup.string(),
      expertise32: Yup.string(),
      expertise33: Yup.string(),
      expertise34: Yup.string(),
      expertise35: Yup.string(),
      expertise36: Yup.string(),
      extra1: Yup.string(),
      extra2: Yup.string(),
      extra3: Yup.string(),
      extra4: Yup.string(),
      extra5: Yup.string(),
      profile: Yup.string(),
      hobbies: Yup.string(),
      proOrganizationId: Yup.number().nullable(),
    }),
    googleReviewLinks: Yup.array().of(
      Yup.object().shape({
        proViewIds: Yup.array()
          .of(Yup.number())
          .min(1, "Minimum un pro doit être associé au lien d'avis Google !"),
        url: Yup.string()
          .required('Lien pour avis Google requis !')
          .test(
            'is-google-review-link',
            'Lien pour avis Google invalide ! Il doit être de la forme https://g.page/r/{variable}/review',
            (url) => {
              const regex = new RegExp(
                '^https:\\/\\/g.page\\/r\\/[a-zA-Z0-9_-]+\\/review$',
              );

              return regex.test(url);
            },
          ),
      }),
    ),

    proHolidays: Yup.array().of(
      Yup.object().shape({
        startDate: Yup.date().nullable().required('Date de début requise !'),
        endDate: Yup.date()
          .when('startDate', (startDate, schema) => {
            if (startDate) {
              return schema.min(
                startDate,
                'La date de fin doit être supérieure à la date de début !',
              );
            }
          })
          .nullable()
          .required('Date de fin requise !'),
      }),
    ),
    proMessages: Yup.array().of(
      Yup.object().shape({
        isPermanent: Yup.boolean(),
        body: Yup.string().required('Message requis !'),
        startDate: Yup.date()
          .nullable()
          .when('isPermanent', {
            is: false,
            then: Yup.date().required('Date de début requise !'),
          }),
        endDate: Yup.date()
          .nullable()
          .when('isPermanent', {
            is: false,
            then: Yup.date()
              .required('Date de fin requise !')
              .when('startDate', (startDate, schema) => {
                if (startDate) {
                  return schema.min(
                    startDate,
                    'La date de fin doit être supérieure à la date de début !',
                  );
                }
              })
              .nullable()
              .required('Date de fin requise !'),
          }),
      }),
    ),
    proPrices: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required('Titre du tarif requis !'),
        content: Yup.string().required('Tarif requis !'),
      }),
    ),
    // TODO: instead of check city, check street
    proPostalAddress: Yup.object().shape({
      postalCode: Yup.number().required('Code postal requis !'),
      street: Yup.string().required('Adresse requise !'),
      city: Yup.string().required("Ville de l'entreprise requise !"),
      geoCoordinates: Yup.object()
        .shape({
          lat: Yup.number(),
          lng: Yup.number(),
        })
        .nullable()
        .required("Coordonnées de l'adresse de l'entreprise requises !"),
    }),
    isFitInGuide: Yup.boolean().oneOf(
      [true],
      'Le contenu de la fiche ne rentre pas dans le guide !',
    ),
  });
};
