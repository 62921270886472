import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { Message } from 'semantic-ui-react';
import TimelineSolicitationActions from '../timeline-solicitation-actions';

type SolicitationDeclinedProps = {
  event: MongoEvent;
};

const SolicitationDeclinedV1: FunctionComponent<SolicitationDeclinedProps> = ({
  event,
}) => (
  <TimelineEvent
    date={event.date}
    color="red"
    icon="mail"
    title="Déclin"
    initiator="pro"
  >
    <h3>
      Demande mail du particulier déclinée par{' '}
      <span>{event.initiator.pro.proPresentation.name}</span>
    </h3>
    <section>
      <p>
        <b>Raison du déclin : </b>
        {event.data.solicitation.declineReason}
      </p>
    </section>
    <section>
      <TimelineSolicitationActions solicitation={event.data.solicitation} />
    </section>
  </TimelineEvent>
);

const SolicitationDeclinedV2: FunctionComponent<SolicitationDeclinedProps> = ({
  event,
}) => (
  <TimelineEvent
    date={event.date}
    color="red"
    icon="mail"
    title="Déclin"
    initiator="pro"
  >
    <h3>
      Demande mail du particulier déclinée par{' '}
      <span>
        {event.actors.pro.proPresentation.name} (
        {event.actors.pro.proView.bilikZone.mainCityCode} -{' '}
        {event.actors.pro.proPresentation.mainTrade?.name})
      </span>{' '}
    </h3>
    <section>
      <p>
        <b>Raison du déclin : </b>
        {event.data.solicitation.declineReason}
      </p>
    </section>
    <section>
      <TimelineSolicitationActions solicitation={event.data.solicitation} />
    </section>
  </TimelineEvent>
);

const getDeclineReason = (type: string, reason?: string) => {
  switch (type) {
    case 'agenda':
      return 'Agenda complet';
    case 'sector':
      return "Hors secteur d'intervention";
    case 'activity':
      return "Hors domaine d'activité";
    case 'spamMarketing':
      return 'Spam : Démarchage commercial';
    case 'spamRecruitment':
      return 'Spam : Demande de stage/alternance/emploi';
    case 'spam':
      return 'Spam';
    default:
      return reason;
  }
};

const SolicitationDeclinedV3: FunctionComponent<SolicitationDeclinedProps> = ({
  event,
}) => (
  <TimelineEvent
    date={event.date}
    color="red"
    icon="mail"
    title="Déclin"
    initiator="pro"
  >
    <h3>
      Demande mail du particulier déclinée par{' '}
      <span>
        {event.actors.pro.proPresentation.name} (
        {event.actors.pro.proView.bilikZone.mainCityCode} -{' '}
        {event.actors.pro.proPresentation.mainTrade?.name})
      </span>{' '}
    </h3>
    <section>
      <p>
        <b>Raison du déclin : </b>
        <br />
        {getDeclineReason(
          event.data.solicitation.declineType,
          event.data.solicitation.declineReason,
        )}
      </p>
    </section>
    <section>
      <TimelineSolicitationActions solicitation={event.data.solicitation} />
    </section>
  </TimelineEvent>
);

const SolicitationDeclined: FunctionComponent<SolicitationDeclinedProps> = ({
  event,
}) => {
  if (event.version === 1) {
    return <SolicitationDeclinedV1 event={event} />;
  } else if (event.version === 2) {
    return <SolicitationDeclinedV2 event={event} />;
  } else if (event.version === 3) {
    return <SolicitationDeclinedV3 event={event} />;
  }
  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour SolicitationDeclined (version ${event.version})`}
    />
  );
};

export default SolicitationDeclined;
