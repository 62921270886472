import * as Yup from 'yup';
import { ToastError } from 'utils';
import Client from 'client';
import gql from 'graphql-tag';
import { isValidFrTelephone } from 'utils/telephone';

const isExistingSlug = async (slug?: string | null): Promise<boolean> => {
  return Client.query({
    query: gql`
      query ($slug: String!) {
        bilikZone(where: { slug: { _eq: $slug } }) {
          id
          slug
        }
      }
    `,
    variables: { slug: slug || '' },
  }).then(
    (response) => response.data.bilikZone && response.data.bilikZone.length > 0,
  );
};

type BilikZoneFormValidationSchemaArgs = {
  currentSlug?: string;
};

export const bilikZoneFormValidationSchema = (
  args?: BilikZoneFormValidationSchemaArgs,
): Yup.ObjectSchema => {
  return Yup.object().shape({
    name: Yup.string().required('Nom requis !'),
    guideName: Yup.string().required('Nom dans le guide requis !'),
    slug: Yup.string()
      .required('Slug requis !')
      .matches(/^[a-z0-9-]+$/, {
        excludeEmptyString: true,
        message: 'Format incorrect !',
      })
      .test('is-slug-uniq', 'Slug déjà existant !', async (slug) => {
        if (args?.currentSlug !== slug) {
          return await isExistingSlug(slug)
            .then((res) => res === false)
            .catch((error) => {
              ToastError(
                'Erreur',
                "Impossible de vérifier l'unicité du slug !",
              );
              throw error;
            });
        }
        return true;
      }),
    addressLocality: Yup.string().required('Ville requise !'),
    postalCode: Yup.string().required('Code postal requis !'),
    streetAddress: Yup.string().required(`Adresse requise !`),
    regionPostalCode: Yup.string().required('Code département requis !'),
    telephone: Yup.string()
      .test('is-valid', 'Numéro de téléphone invalide !', isValidFrTelephone)
      .required('Numéro de téléphone requis !'),
    genericEmail: Yup.string()
      .required('Email générique requis !')
      .email('Email invalide !'),
    teamMessage: Yup.string().required(`Message de l'équipe requis !`),
    mainCityId: Yup.number().required('Ville principale requise !'),
    mainCityCode: Yup.string()
      .required('Code postal de la ville principale requis !')
      .length(3, 'Format invalide !')
      .uppercase(),
    managers: Yup.array().of(Yup.string()).min(1),
    members: Yup.array().of(Yup.string()),
    callTrackingTarget: Yup.object().shape({
      name: Yup.string().required('Nom requis !'),
      telephone: Yup.string()
        .test('is-valid', 'Numéro de téléphone invalide !', isValidFrTelephone)
        .required('Numéro de téléphone requis !'),
    }),
  });
};
