import React, { FunctionComponent } from 'react';
import {
  Button,
  Container,
  Form,
  Icon,
  Loader,
  Message,
} from 'semantic-ui-react';
import { ProReviewInvitationMailsFormFields } from './pro-review-invitation-emails-form-field.type';
import { useFormikContext } from 'formik';
import FieldError from 'components/field-error/field-error';
import 'reactjs-tiptap-editor/style.css';
import { useEditor, EditorContent } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import HardBreak from '@tiptap/extension-hard-break';
import styled from 'styled-components';

interface Props {
  fetchEmails: (forceDefault?: boolean) => Promise<
    | {
        firstEmail: string;
        dunningEmail: string;
      }
    | undefined
  >;
  loading: boolean;
}

const StyledEditorContent = styled(EditorContent)`
  border: 1px solid #d4d4d5;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;

  .ProseMirror:focus {
    outline: none !important;
  }
`;

const ProReviewInvitationsEmailsFormView: FunctionComponent<Props> = ({
  fetchEmails,
  loading,
}) => {
  const {
    values,
    handleSubmit,
    submitForm,
    isSubmitting,
    setFieldValue,
    errors,
  } = useFormikContext<ProReviewInvitationMailsFormFields>();

  const extensions = [Document, Paragraph, Text, HardBreak];
  const editorFirstEmail = useEditor(
    {
      extensions,
      onBlur: ({ editor }) => {
        setFieldValue('customReviewInvitationEmail', editor.getHTML());
      },
      content: values.customReviewInvitationEmail,
    },
    [values.customReviewInvitationEmail],
  );

  const editorDunningEmail = useEditor(
    {
      extensions,
      onBlur: ({ editor }) => {
        setFieldValue('customReviewInvitationDunningEmail', editor.getHTML());
      },
      content: values.customReviewInvitationDunningEmail,
    },
    [values.customReviewInvitationDunningEmail],
  );

  const [step, setStep] = React.useState<number>(1);

  return (
    <Form onSubmit={handleSubmit}>
      <Message>
        <p>
          Vous avez à votre disposition plusieurs variables pour personnaliser
          les mails. Pour les utiliser, il suffit d’insérer la variable
          souhaitée dans votre texte en respectant bien cette écriture :{' '}
          <strong>[nom_de_la_variable]</strong>.
        </p>
        <ul>
          <li>
            <strong>{'[sujet: contenu du sujet]'}</strong> : sujet du mail
            (modifiez uniquement le contenu après &quot;sujet:&quot;)
          </li>
          <li>
            <strong>{'[nom]'}</strong> : prénom du particulier
          </li>
          <li>
            <strong>{'[prenom]'}</strong> : nom du particulier
          </li>
          <li>
            <strong>{'[lien]'}</strong> : lien vers le formulaire (obligatoire)
          </li>
          <li>
            <strong>{'[signature]'} </strong> : nom et prénom du contact ou
            &quot;L&apos;équipe&quot; si plusieurs contacts + nom de la fiche
          </li>
          <li>
            <strong>{'[metiers]'} </strong>: métier principal / métier bis de la
            fiche
          </li>
        </ul>
      </Message>
      <Container
        style={{
          marginBottom: '15px',
          display: 'flex',
        }}
      >
        <Button.Group>
          <Button
            color="grey"
            basic={step !== 1}
            type="button"
            onClick={() => setStep(1)}
          >
            Premier mail
          </Button>
          <Button
            color="grey"
            type="button"
            onClick={() => setStep(2)}
            basic={step !== 2}
          >
            Mail de rappel
          </Button>
        </Button.Group>
      </Container>
      {loading ? (
        <>Chargement...</>
      ) : (
        <>
          {step === 1 && (
            <>
              <StyledEditorContent editor={editorFirstEmail} />
              <FieldError name="customReviewInvitationEmail">
                {errors.customReviewInvitationEmail}
              </FieldError>
            </>
          )}
          {step === 2 && (
            <>
              <StyledEditorContent editor={editorDunningEmail} />
              <FieldError name="customReviewInvitationDunningEmail">
                {errors.customReviewInvitationDunningEmail}
              </FieldError>
            </>
          )}
        </>
      )}
      <Container textAlign="right" style={{ marginTop: '15px' }}>
        <Button
          type="reset"
          onClick={() => {
            fetchEmails(true).then((emails) => {
              if (emails) {
                setFieldValue('customReviewInvitationEmail', emails.firstEmail);
                setFieldValue(
                  'customReviewInvitationDunningEmail',
                  emails.dunningEmail,
                );
              }
            });
          }}
        >
          <Icon name="undo" />
          Réinitialiser
        </Button>
        <Button
          type="button"
          onClick={submitForm}
          loading={isSubmitting}
          disabled={isSubmitting}
          primary
        >
          <Icon name="save" />
          Enregistrer
        </Button>
      </Container>
    </Form>
  );
};

export default ProReviewInvitationsEmailsFormView;
