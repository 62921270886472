import { apiClient } from 'axios-client';
import { Formik, useFormikContext } from 'formik';
import { ProFormArchiveModalQuery } from 'generated/graphql';
import { ProFormUpdateFields } from 'pages/pros/pro-form/pro-form-update-fields-type';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { equals } from 'remeda';
import { ToastError } from 'utils';
import { flattenObject } from 'utils/flatten-object';
import { getFormErrorMessage } from 'utils/form-error-message';
import {
  ProFormArchiveFields,
  ProFormArchiveModalProps,
} from './pro-form-archive-modal-repository';
import ProFormArchiveModalView from './pro-form-archive-modal-view';
import * as Yup from 'yup';

type ProFormArchiveModalContainerProps = ProFormArchiveModalProps & {
  otherProViews?: NonNullable<
    ProFormArchiveModalQuery['proOrganizationByPk']
  >['proPresentations'][0]['proViews'];
  proViewId: number;
  proOrganizationId?: number;
};

const ProFormArchiveModalContainer: FunctionComponent<
  ProFormArchiveModalContainerProps
> = ({ proViewId, otherProViews, onClose, ...rest }) => {
  const { setFieldValue, validateForm, handleSubmit } =
    useFormikContext<ProFormUpdateFields>();

  const onArchive = useCallback(
    async (values: ProFormArchiveFields): Promise<void> => {
      const errors = await validateForm();

      const proViewIds = values.proViewIds;

      if (equals(errors, {})) {
        await apiClient
          .post(`/form/pro-view/archive`, {
            proViewIds,
          })
          .catch((error) => {
            ToastError(
              'Erreur',
              "erreur lors de la notification de l'archivage aux particuliers",
            );
            throw error;
          });

        // Update the proView date archived to prevent the proView from being published again on handleSubmit
        setFieldValue('status', 'archived');

        setTimeout(() => handleSubmit());
      } else {
        const errorValues = Object.values(flattenObject(errors)) as string[];
        const errorMsg = getFormErrorMessage(errorValues);

        ToastError("Impossible d'archiver la fiche", errorMsg);
      }
    },
    [proViewId],
  );

  const initialValues: ProFormArchiveFields = useMemo(
    () => ({
      proViewIds: [proViewId],
    }),
    [proViewId],
  );

  return (
    <Formik
      onSubmit={onArchive}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        proViewIds: Yup.array().min(1, 'Fiche requise !'),
      })}
    >
      <ProFormArchiveModalView
        onClose={onClose}
        otherProViews={otherProViews}
        {...rest}
      />
    </Formik>
  );
};

export default ProFormArchiveModalContainer;
