import { apiClient } from 'axios-client';
import ConfirmModal from 'components/confirm-modal/confirm-modal';
import FieldError from 'components/field-error/field-error';
import Label from 'components/label/label';
import { useFormikContext } from 'formik';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { Button, FormField, Label as LabelUi } from 'semantic-ui-react';
import { formatTelephone, ToastError, ToastSuccess } from 'utils';
import { ProFormUpdateFields } from '../pro-form-update-fields-type';

type ProTelephonesFieldProps = {
  proViewId: number;
};

const ProTelephonesField: FunctionComponent<ProTelephonesFieldProps> = ({
  proViewId,
}) => {
  const { values, errors, setFieldValue } =
    useFormikContext<ProFormUpdateFields>();

  const renderTelephoneStatusLabel = (telephoneStatus: string) => {
    if (telephoneStatus === 'unverified') {
      return null;
    }

    const statusText =
      telephoneStatus === 'active' ? 'Actif' : 'En cours de vérification';
    const statusColor = telephoneStatus === 'active' ? 'green' : 'orange';

    return (
      <LabelUi size="big" color={statusColor}>
        {statusText}
      </LabelUi>
    );
  };

  const onBuyProNumber = useCallback(async (): Promise<void> => {
    if (!values.proPresentation.proOrganizationId) {
      ToastError(
        'Erreur',
        'Vous devez dabord ajouter une entreprise avant de pouvoir générer un numéro',
      );
      return;
    }

    await apiClient
      .post(`/form/telnyx/set-pro-number`, {
        proViewId,
      })
      .then((response) => {
        ToastSuccess('Succès', 'Numéro de téléphone attribué');
        setFieldValue('telephones', [
          ...values.telephones,
          ...response.data.map((telephone) => ({
            phoneNumber: telephone,
            status: 'unverified',
          })),
        ]);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          ToastError(
            'Erreur',
            "Il n'y a plus de numéros TELNYX disponibles pour le moment.",
          );
        } else {
          ToastError('Erreur', "Impossible d'attribuer les numéros TELNYX");
        }
        throw error;
      });
  }, [proViewId, values.telephones]);

  useEffect(() => {
    if (!values.telephones.length) return;

    const updatedTelephones = [...values.telephones];
    for (const telephone of values.telephones.slice(0, 2)) {
      apiClient
        .get(`/form/telnyx/get-pro-number/${telephone.phoneNumber}`)
        .then((response) => {
          const telephone = response.data;
          const phoneNumber = telephone.phone_number;
          const status = telephone.status;

          if (!phoneNumber) return;

          const index = updatedTelephones.findIndex(
            (tel) => tel.phoneNumber === phoneNumber,
          );

          if (index !== -1) {
            updatedTelephones[index] = {
              ...updatedTelephones[index],
              status,
            };
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setFieldValue('telephones', updatedTelephones);
        });
    }
  }, [proViewId, values.telephones.length]);

  return (
    <>
      {values.status !== 'archived' &&
      (!values.telephones || values.telephones.length === 0) ? (
        <>
          <ConfirmModal
            trigger={
              <Button
                type="button"
                className="warning"
                compact
                onClick={onBuyProNumber}
              >
                Générer les numéros
              </Button>
            }
            header="Confirmation"
            content={
              <p>
                Cette action va attribuer deux nouveaux numéros Telnyx. Un pour
                le web et un pour le guide. Continuer ?
              </p>
            }
            onConfirm={onBuyProNumber}
          />
        </>
      ) : null}

      {values.telephones && values.telephones.length > 0 ? (
        <FormField>
          {values.telephones?.[0] && (
            <Label>
              <span style={{ width: '40px', display: 'inline-block' }}>
                Web
              </span>
              <LabelUi
                key={`call-tracking-number-web`}
                size="big"
                style={{ marginLeft: '15px' }}
              >
                {formatTelephone(values.telephones[0].phoneNumber)}
              </LabelUi>
              {renderTelephoneStatusLabel(values.telephones[0].status)}
            </Label>
          )}

          {values.telephones?.[1] && (
            <Label>
              <>
                <span style={{ width: '40px', display: 'inline-block' }}>
                  Guide
                </span>
                <LabelUi
                  key={`call-tracking-number-guide`}
                  size="big"
                  style={{ marginLeft: '15px', display: 'inline-block' }}
                >
                  {formatTelephone(values.telephones[1].phoneNumber)}
                </LabelUi>
                {renderTelephoneStatusLabel(values.telephones[1].status)}
              </>
            </Label>
          )}

          {values.telephones?.slice(2, values.telephones.length).length ? (
            <Label>
              <span style={{ width: '40px', display: 'inline-block' }}>
                Autre(s)
              </span>
              {values.telephones
                ?.slice(2, values.telephones.length)
                .map((telephone) => (
                  <LabelUi
                    key={`call-tracking-number-old`}
                    size="big"
                    style={{ marginLeft: '15px' }}
                  >
                    {formatTelephone(telephone.phoneNumber)}
                  </LabelUi>
                ))}
            </Label>
          ) : null}
        </FormField>
      ) : null}
      {errors.telephones ? (
        <div>
          <FieldError color="red">{errors.telephones}</FieldError>
        </div>
      ) : null}
      <div style={{ height: '15px' }} />
    </>
  );
};

export default ProTelephonesField;
