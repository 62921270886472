import { Formik, FormikHelpers } from 'formik';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ToastError, ToastSuccess } from 'utils/toast';
import { BilikZoneFormFields } from './bilik-zone-form-fields.type';
import BilikZoneFormView from './bilik-zone-form-view';
import { BilikZoneInsertInput, CityUpdates } from 'generated/graphql';
import { bilikZoneFormValidationSchema } from './bilik-zone-form-validation-schema';
import { useNavigate } from 'react-router-dom';
import { addCrs } from 'utils/geometry';
import { formatE164Telephone } from 'utils';

type BilikZoneFormCreateProps = {
  createBilikZone: (
    bilikZone: BilikZoneInsertInput,
  ) => Promise<number | undefined>;
  updateCities: (cityUpdates: CityUpdates[]) => Promise<unknown>;
};

const BilikZoneFormCreateContainer: FunctionComponent<
  BilikZoneFormCreateProps
> = ({ createBilikZone, updateCities }) => {
  const history = useNavigate();

  const initialValues: BilikZoneFormFields = useMemo(() => {
    return {
      name: '',
      guideName: '',
      mainCityCode: '',
      teamMessage: '',
      slug: '',
      genericEmail: '',
      telephone: '',
      addressLocality: '',
      postOfficeBoxNumber: '',
      streetAddress: '',
      postalCode: '',
      regionPostalCode: '',
      status: 'published',
      zohoOrganizationId: undefined,
      geoCoordinates: undefined,
      radius: 5000,
      callTrackingTarget: {
        name: '',
        telephone: '',
      },
      cities: [],
      managers: [],
      members: [],
      hasMultiplePostalCode: false,
      hasDistrict: false,
      mainCityId: null,
    };
  }, []);

  const onSubmit = useCallback(
    async (
      values: BilikZoneFormFields,
      actions: FormikHelpers<BilikZoneFormFields>,
    ): Promise<void> => {
      try {
        const bilikZoneId = await createBilikZone({
          name: values?.name,
          guideName: values?.guideName,
          mainCityCode: values?.mainCityCode,
          teamMessage: values?.teamMessage,
          slug: values?.slug,
          genericEmail: values?.genericEmail,
          telephone: formatE164Telephone(values?.telephone),
          addressLocality: values?.addressLocality,
          postOfficeBoxNumber: values?.postOfficeBoxNumber,
          streetAddress: values?.streetAddress,
          postalCode: values?.postalCode,
          regionPostalCode: values?.regionPostalCode,
          status: values?.status,
          zohoOrganizationId: values?.zohoOrganizationId,
          mainCityId: values?.mainCityId ?? 0,
          callTrackingTarget: {
            ...values?.callTrackingTarget,
            telephone: values?.callTrackingTarget.telephone
              ? formatE164Telephone(values?.callTrackingTarget.telephone)
              : undefined,
          },
          managers: {
            data: values?.managers.map((bilikPersonId) => ({
              bilikPersonId,
            })),
          },
          members: {
            data: values?.members.map((bilikPersonId) => ({
              bilikPersonId,
            })),
          },
          hasMultiplePostalCode: values?.hasMultiplePostalCode,
          hasDistrict: values?.hasDistrict,
        });

        if (!bilikZoneId) {
          ToastError('Erreur', "Impossible d'enregistrer la zone");
        }

        const cities: CityUpdates[] = values?.cities.map((city) => {
          return {
            where: { id: { _eq: city.id } },
            _set: {
              bilikZoneId: bilikZoneId,
            },
          };
        });

        const cityParentIds = values?.cities
          .filter((city) => city.parentId)
          .map((city) => city.parentId);

        cityParentIds.forEach((parentId) => {
          if (parentId) {
            cities.push({
              where: { id: { _eq: parentId } },
              _set: {
                bilikZoneId: bilikZoneId,
              },
            });
          }
        });

        await updateCities(cities);

        ToastSuccess('Succès', 'Zone enregistrée et villes associées');
        actions.setSubmitting(false);
        history('/bilik_zones');
      } catch {
        ToastError('Erreur', "Impossible d'enregistrer la zone");
      }
    },
    [createBilikZone, history],
  );

  return (
    <Formik
      validateOnChange={false}
      validationSchema={bilikZoneFormValidationSchema()}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <BilikZoneFormView />
    </Formik>
  );
};

export default BilikZoneFormCreateContainer;
