import { Formik } from 'formik';
import { ProPresentationSetInput } from 'generated/graphql';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ToastError, ToastSuccess } from 'utils/toast';
import { ProReviewInvitationMailsFormFields } from './pro-review-invitation-emails-form-field.type';
import proReviewInvitationEmailsFormValidationSchema from './pro-review-invitation-emails-form-validation-schema';
import ProReviewInvitationsEmailsFormView from './pro-review-invitation-emails-form-view';

type ProReviewInvitationsEmailsFormContainerProps = {
  proPresentationId: number;
  fetchEmails: (forceDefaultEmails?: boolean) => Promise<
    | {
        firstEmail: string;
        dunningEmail: string;
      }
    | undefined
  >;
  updateProReviewInvitationEmails: (
    id: number,
    proPresentation: ProPresentationSetInput,
  ) => Promise<void>;
  onSuccess?: () => void;
};

const ProReviewInvitationsEmailsFormContainer: FunctionComponent<
  ProReviewInvitationsEmailsFormContainerProps
> = ({
  proPresentationId,
  fetchEmails,
  updateProReviewInvitationEmails,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [firstEmail, setFirstEmail] = React.useState<string>('');
  const [dunningEmail, setDunningEmail] = React.useState<string>('');

  const onSubmit = useCallback(
    async (values: ProPresentationSetInput) => {
      setLoading(true);
      updateProReviewInvitationEmails(proPresentationId, values)
        .then(() => {
          ToastSuccess('Succès', "Emails de demande d'avis enregistrés");
        })
        .catch((error) => {
          ToastError('Erreur', 'Impossible de mettre à jour les emails');
          throw error;
        })
        .finally(() => {
          setLoading(false);
          onSuccess?.();
        });
    },
    [updateProReviewInvitationEmails],
  );

  const initialValues: ProReviewInvitationMailsFormFields = useMemo(() => {
    return {
      customReviewInvitationEmail: firstEmail,
      customReviewInvitationDunningEmail: dunningEmail,
    };
  }, [firstEmail, dunningEmail]);

  useEffect(() => {
    fetchEmails().then((emails) => {
      setFirstEmail(emails?.firstEmail || '');
      setDunningEmail(emails?.dunningEmail || '');
    });
  }, []);

  return (
    <Formik
      validationSchema={proReviewInvitationEmailsFormValidationSchema}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
    >
      <ProReviewInvitationsEmailsFormView
        loading={loading}
        fetchEmails={fetchEmails}
      />
    </Formik>
  );
};

export default ProReviewInvitationsEmailsFormContainer;
