import { ProReviewInvitationsTableQuery } from 'generated/graphql';
import React, { FunctionComponent } from 'react';
import { Loader, Message } from 'semantic-ui-react';
import ProReviewInvitationsTableView from './pro-review-invitations-table-view';

type ProReviewInvitationsTableContainerProps = {
  loading: boolean;
  proReviewInvitations?: ProReviewInvitationsTableQuery['proReviewInvitation'];
  orderBy?: {
    column: string;
    direction: string;
  };
  onSort?: (column: string, direction: string) => void;
  onFilter?: (column: string, value: string) => void;
};

const ProReviewInvitationsTableContainer: FunctionComponent<
  ProReviewInvitationsTableContainerProps
> = ({ proReviewInvitations, loading, onSort, onFilter, orderBy }) => {
  if (!proReviewInvitations) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  if (!proReviewInvitations.length) {
    return <Message>Il n&apos;y a pas de résultats</Message>;
  }

  return (
    <ProReviewInvitationsTableView
      loading={loading}
      proReviewInvitations={proReviewInvitations}
      onSort={onSort}
      orderBy={orderBy}
      onFilter={onFilter}
    />
  );
};

export default ProReviewInvitationsTableContainer;
