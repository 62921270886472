import { format } from 'date-fns';
import { ProReviewInvitationsTableQuery } from 'generated/graphql';
import React, { FunctionComponent, useMemo } from 'react';
import {
  Button,
  Dimmer,
  Icon,
  Loader,
  Modal,
  Popup,
  Table,
} from 'semantic-ui-react';
import { formatDateTime } from 'utils';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import { ProReviewInvitationAutorespondToggle } from 'components/pro-review-invitation-autorespond-toggle/pro-review-invitation-autorespond-toggle';

type ProReviewInvitationsTableViewProps = {
  loading: boolean;
  proReviewInvitations: ProReviewInvitationsTableQuery['proReviewInvitation'];
  orderBy?: {
    column: string;
    direction: string;
  };
  onSort?: (column: string, direction: string) => void;
  onFilter?: (column: string, value: string) => void;
};

const ProReviewInvitationsTableView: FunctionComponent<
  ProReviewInvitationsTableViewProps
> = ({ proReviewInvitations, loading, orderBy, onSort, onFilter }) => {
  const history = useNavigate();
  const { currentBilikPerson } = useCurrentBilikPerson();

  const direction = useMemo(() => {
    switch (orderBy?.direction) {
      case 'ascending':
      case 'descending':
        return orderBy.direction;
      default:
        return 'ascending';
    }
  }, [orderBy?.direction]);

  return (
    <>
      <Dimmer.Dimmable>
        <Dimmer inverted verticalAlign="top" active={loading}>
          <Loader style={{ marginTop: '20px' }} inline>
            Chargement...
          </Loader>
        </Dimmer>
        <Table celled striped sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={
                  orderBy?.column === 'dateCreated' ? direction : undefined
                }
                onClick={(): void => {
                  if (onSort) {
                    onSort(
                      'dateCreated',
                      direction === 'ascending' ? 'descending' : 'ascending',
                    );
                  }
                }}
              >
                Date de la demande
              </Table.HeaderCell>
              <Table.HeaderCell className="not sortable">
                Particulier
              </Table.HeaderCell>
              <Table.HeaderCell className="not sortable">
                Email status
              </Table.HeaderCell>
              <Table.HeaderCell className="not sortable">Avis</Table.HeaderCell>
              <Table.HeaderCell className="not sortable">
                Relance
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {proReviewInvitations.map((proReviewInvitation) => (
              <Table.Row key={proReviewInvitation.id}>
                <Table.Cell>
                  {proReviewInvitation.dateCreated
                    ? formatDateTime(proReviewInvitation.dateCreated)
                    : null}
                  <div>
                    {proReviewInvitation.dateOfWork ? (
                      <>
                        Date des travaux:{' '}
                        {format(
                          new Date(proReviewInvitation.dateOfWork),
                          'MM/yyyy',
                        )}
                      </>
                    ) : null}
                  </div>
                  {currentBilikPerson?.roleName === 'admin' ? (
                    <div>
                      <strong>UUID :</strong> {proReviewInvitation.uuid}
                    </div>
                  ) : null}
                </Table.Cell>
                <Table.Cell>
                  {`${proReviewInvitation.givenName} ${proReviewInvitation.familyName}`}
                  <br />
                  <a
                    href="#/"
                    onClick={(): void => {
                      if (onFilter) {
                        onFilter('email', proReviewInvitation.email);
                      }
                    }}
                  >
                    {proReviewInvitation.email}
                  </a>
                  <br />
                  <Popup
                    position="top center"
                    trigger={
                      <Link
                        to={`/timeline/${proReviewInvitation.email}`}
                        style={{
                          textDecoration: 'none',
                        }}
                      >
                        <Icon fitted name="time" />
                        <Icon fitted name="long arrow alternate down" />
                      </Link>
                    }
                    content="Timeline du particulier"
                  />
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {proReviewInvitation.isBounce ? (
                    <Popup
                      position="top center"
                      content="Email invalide"
                      trigger={
                        <Icon
                          size="small"
                          bordered
                          inverted
                          name="remove"
                          color="red"
                        />
                      }
                    />
                  ) : (
                    <Popup
                      position="top center"
                      content="Email valide"
                      trigger={
                        <Icon
                          size="small"
                          bordered
                          inverted
                          name="checkmark"
                          color="green"
                        />
                      }
                    />
                  )}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {proReviewInvitation.proReviewId ? (
                    <Popup
                      content="Voir l'avis"
                      position="top center"
                      trigger={
                        <Button
                          icon
                          onClick={(): void => {
                            history(
                              `/pro_reviews?proReviewId=${proReviewInvitation.proReviewId}`,
                            );
                          }}
                          type="button"
                        >
                          <Icon name="comment" />
                        </Button>
                      }
                    />
                  ) : null}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <ProReviewInvitationAutorespondToggle
                    id={proReviewInvitation.id}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Dimmer.Dimmable>
    </>
  );
};

export default ProReviewInvitationsTableView;
