import {
  Button,
  Form,
  Icon,
  FormField as FormFieldUi,
  DropdownItemProps,
  Divider,
  Modal,
  Container,
  Select,
  Transition,
  Table,
} from 'semantic-ui-react';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import FormField from 'components/form-field/form-field';
import { FieldArray, useFormikContext } from 'formik';
import { ProContractFormFields } from './pro-contract-form-fields';
import SelectBilikPersonField from 'components/select-bilik-person-field/select-bilik-person-field';
import { ProContractFormModalProps } from './pro-contract-form-modal';
import Label from 'components/label/label';
import { PreviewSubscriptionRow } from './pro-contract-form-container';
import {
  addDays,
  addMonths,
  differenceInDays,
  endOfMonth,
  getDaysInMonth,
  getMonth,
  isBefore,
  setDate,
} from 'date-fns';
import { formatDate } from 'utils';
import { fr } from 'date-fns/locale';
import { range } from 'remeda';
import { useIsUserAdmin } from 'hooks/use-is-user-admin/use-is-user-admin';

type ProContractFormViewProps = ProContractFormModalProps & {
  proViewId: number;
  proOrganizationId: number;
  proPersonSuggestions?: {
    givenName: string;
    familyName: string;
    telephone: string;
  }[];
  isProPublished: boolean;
};

const emptyDiscount = {
  occurencesNumber: null,
  appliedAmount: null,
};

const buildAmountOptions = ({
  acc,
  amount,
  baseAmount,
}: {
  acc: DropdownItemProps[];
  amount: number;
  baseAmount: number;
}) => {
  const reductionPercentage = Math.round(
    ((baseAmount - amount) / baseAmount) * 100,
  );

  return [
    ...acc,
    {
      key: amount,
      // Condition for the selector input to accept 0 value
      value: amount === 0 ? '0' : amount,
      text: (
        <p>
          {amount} € &nbsp;&nbsp;&nbsp;&nbsp;
          {reductionPercentage > 0 ? (
            <span
              style={{
                color: 'green',
              }}
            >
              ( -{reductionPercentage}% )
            </span>
          ) : null}
        </p>
      ),
    },
  ];
};

const byDate = (a: PreviewSubscriptionRow, b: PreviewSubscriptionRow) =>
  a.dateISO < b.dateISO ? -1 : 1;

const ProContractFormView: FunctionComponent<ProContractFormViewProps> = ({
  onClose,
  open,
  proViewId,
  proPersonSuggestions,
  isProPublished,
}) => {
  const {
    handleReset,
    handleSubmit,
    setFieldValue,
    submitForm,
    isSubmitting,
    values,
    initialValues,
  } = useFormikContext<ProContractFormFields>();

  const [subscriptionPreviewRows, setSubscriptionPreviewRows] = useState<
    PreviewSubscriptionRow[]
  >([]);

  const [step, setStep] = useState<1 | 2>(1);
  const isUserAdmin = useIsUserAdmin();

  const buildPreviewEntranceFeeRows = useCallback(
    (values: ProContractFormFields) => {
      if (!values.entranceFee) return;

      const occurencesNumber = values.entranceFee.monthSpreadAmount;
      const baseAmount = values.entranceFee.appliedAmount;
      const now = new Date();
      const date = isBefore(now, setDate(now, 5))
        ? setDate(now, 5)
        : setDate(addMonths(now, 1), 5);

      const appliedAmount =
        Math.ceil((baseAmount / occurencesNumber) * 100) / 100;

      const previewEntranceFeeRows: PreviewSubscriptionRow[] = [];

      range(0, occurencesNumber).forEach((index) => {
        const occurenceDate = addMonths(date, index);
        previewEntranceFeeRows.push({
          date: formatDate(occurenceDate.toISOString()),
          dateISO: occurenceDate.toISOString(),
          amount: `${String(appliedAmount)} €`,
          text: `Droit d'entrée
          ${
            occurencesNumber > 1
              ? 'étalé sur ' + occurencesNumber + ' mois'
              : ''
          }`,
        });
      });

      setSubscriptionPreviewRows((prev) =>
        [...prev, ...previewEntranceFeeRows].sort(byDate),
      );
    },
    [],
  );

  const buildPreviewSubscriptionsRows = useCallback(
    (values: ProContractFormFields) => {
      const now = new Date();
      const previewSubscriptionsRows: PreviewSubscriptionRow[] = [];
      const startDate = new Date(values.startDate || now);
      const startDateOnFifth = setDate(startDate, 5);

      // Apply prorate when :
      // - Pro has never been published
      // - Date is before the 25th and after 4th of the month.
      const shouldApplyProrate =
        startDate.getDate() < 25 && startDate.getDate() > 4 && !isProPublished;

      if (shouldApplyProrate) {
        const prorateDays = differenceInDays(endOfMonth(startDate), startDate);
        const prorateStartDate = addDays(startDate, 1); // Do not include the current day in the prorate

        const invoiceDate = isBefore(startDate, setDate(startDate, 5))
          ? setDate(startDate, 5)
          : setDate(addMonths(startDate, 1), 5);

        const amount = values.discounts?.length
          ? values.discounts[0].appliedAmount
          : values.subscriptionAmount;

        const prorateAmount =
          Math.floor(
            ((prorateDays * 1) / getDaysInMonth(startDate)) * amount * 100,
          ) / 100;

        previewSubscriptionsRows.push({
          date: formatDate(invoiceDate.toISOString()),
          dateISO: invoiceDate.toISOString(),
          amount: `${String(prorateAmount)} €`,
          text: `Prorata appliqué (si signé aujourd'hui) du ${formatDate(
            prorateStartDate.toISOString(),
          )} au ${formatDate(
            endOfMonth(prorateStartDate).toISOString(),
          )} pour le mois de ${fr.localize?.month(getMonth(prorateStartDate))}`,
        });
      }

      let subscriptionDateBegin =
        startDate.getDate() >= 5
          ? addMonths(startDateOnFifth, 1)
          : startDateOnFifth;

      // Subscriptions
      values.discounts?.forEach((discount, index) => {
        const { appliedAmount } = discount;

        const firstOccurenceDate = subscriptionDateBegin;

        // Remove one occurencesNumber on first discount if prorate is applied
        const occurencesNumber =
          shouldApplyProrate && index === 0
            ? discount.occurencesNumber - 1
            : discount.occurencesNumber;

        if (occurencesNumber === 0) return;

        range(0, occurencesNumber || 0).forEach((index) => {
          const occurenceDate = addMonths(firstOccurenceDate, index);
          previewSubscriptionsRows.push({
            date: formatDate(occurenceDate.toISOString()),
            dateISO: occurenceDate.toISOString(),
            amount: `${String(appliedAmount)} €`,
            text: `Adhésion à Bilik pour le mois de ${fr.localize?.month(
              getMonth(occurenceDate),
            )}`,
          });
        });

        subscriptionDateBegin = addMonths(
          subscriptionDateBegin,
          occurencesNumber,
        );
      });

      previewSubscriptionsRows.push({
        date: formatDate(subscriptionDateBegin.toISOString()),
        dateISO: subscriptionDateBegin.toISOString(),
        amount: `${values.subscriptionAmount} €`,
        text: `Adhésion à Bilik pour le mois de ${fr.localize?.month(
          getMonth(subscriptionDateBegin),
        )}`,
      });

      previewSubscriptionsRows.push({
        date: '...',
        dateISO: addMonths(subscriptionDateBegin, 1).toISOString(),
        text: `Adhésion à Bilik pour ...`,
        amount: `${values.subscriptionAmount} €`,
      });

      setSubscriptionPreviewRows((prev) =>
        [...prev, ...previewSubscriptionsRows].sort(byDate),
      );
    },
    [],
  );

  const entranceFeeAmountOptions: DropdownItemProps[] = useMemo(() => {
    const applicableAmounts = [
      0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500,
    ];
    const baseAmount = Math.max(...applicableAmounts);

    return applicableAmounts.reduce(
      (acc: DropdownItemProps[], amount: number) => {
        return buildAmountOptions({
          acc,
          amount,
          baseAmount,
        });
      },
      [],
    );
  }, [values.subscriptionAmount]);

  const appliedAmountOptions: DropdownItemProps[] = useMemo(() => {
    const baseAmount = values.subscriptionAmount;
    const applicableAmounts = [0, 80, 110, 130, 160, 210, 260, 265, 315];

    return applicableAmounts.reduce(
      (acc: DropdownItemProps[], amount: number) => {
        if (amount >= baseAmount) {
          return acc;
        }

        // Condition used to remove 260€ reduction for 315€ base amount
        if (baseAmount === 315 && amount === 260) {
          return acc;
        }

        return buildAmountOptions({
          acc,
          amount,
          baseAmount,
        });
      },
      [],
    );
  }, [values.subscriptionAmount]);

  const proPersonSuggestionOptions = useMemo(() => {
    const proPersonOptions =
      proPersonSuggestions?.map((signatory, index) => ({
        key: `pro-person-options-${index}`,
        text: `${signatory.givenName} ${signatory.familyName}`,
        property: {
          givenName: signatory.givenName,
          familyName: signatory.familyName,
        },
        value: `${signatory.givenName} ${signatory.familyName}`,
      })) || [];

    // Adding the custom option
    proPersonOptions.push({
      key: `pro-person-options-custom`,
      text: 'Personnalisé',
      property: {
        givenName: '',
        familyName: '',
      },
      value: 'custom',
    });

    return proPersonOptions;
  }, [proPersonSuggestions]);

  const [signatoryValue, setSignatoryValue] = useState<string>(
    initialValues.signatory
      ? `${initialValues.signatory.givenName} ${initialValues.signatory.familyName}`
      : '',
  );

  const [interlocutorValue, setInterlocutorValue] = useState<string>('');

  const handleModalClose = useCallback(() => {
    onClose();
    setSubscriptionPreviewRows([]);
    setStep(1);
  }, [onClose]);

  useEffect(() => {
    const currentSignatoryValue = proPersonSuggestionOptions.filter(
      (option) =>
        `${option.property.givenName} ${option.property.familyName}` ===
        `${initialValues.signatory.givenName} ${initialValues.signatory.familyName}`,
    )[0]?.value;

    const currentInterlocutorValue = proPersonSuggestionOptions.filter(
      (option) =>
        `${option.property.givenName} ${option.property.familyName}` ===
        `${initialValues.interlocutor.givenName} ${initialValues.interlocutor.familyName}`,
    )[0]?.value;

    setSignatoryValue(
      currentSignatoryValue
        ? currentSignatoryValue
        : initialValues.signatory
        ? 'custom'
        : '',
    );
    setInterlocutorValue(
      currentInterlocutorValue
        ? currentInterlocutorValue
        : initialValues.interlocutor
        ? 'custom'
        : '',
    );
  }, [initialValues, proPersonSuggestionOptions]);

  return (
    <>
      <Modal
        open={open}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeIcon
        onClose={handleModalClose}
      >
        <>
          <Modal.Header>
            {step === 1 ? 'Création du contrat' : 'Prévisualisation'}
          </Modal.Header>
          <Modal.Content>
            {step === 1 && (
              <Form onReset={handleReset} onSubmit={handleSubmit}>
                <FormFieldUi>
                  <Label required>Signataire</Label>
                  <Select
                    onChange={(e, { value }): void => {
                      if (value !== undefined && value !== null) {
                        const selectedOption =
                          proPersonSuggestionOptions?.filter(
                            (option) => option.value === value,
                          )[0];
                        setFieldValue(
                          'signatory.givenName',
                          selectedOption.property.givenName,
                        );
                        setFieldValue(
                          'signatory.familyName',
                          selectedOption.property.familyName,
                        );
                        setSignatoryValue(selectedOption.value);
                      }
                    }}
                    placeholder="Sélectionnez le signataire (Représentant légal)"
                    value={signatoryValue}
                    options={proPersonSuggestionOptions}
                  />
                </FormFieldUi>
                <Transition.Group duration={250}>
                  {signatoryValue === 'custom' ? (
                    <Container fluid style={{ paddingLeft: '50px' }}>
                      <Form.Group widths="equal">
                        <FormField
                          type="text"
                          placeholder="Ex: Nom"
                          label="Nom"
                          name="signatory.familyName"
                          required
                        />
                        <FormField
                          type="text"
                          placeholder="Ex: Prénom"
                          label="Prénom"
                          name="signatory.givenName"
                          required
                        />
                      </Form.Group>
                    </Container>
                  ) : null}
                </Transition.Group>
                <FormField
                  type="text"
                  placeholder="Ex: Gérant"
                  label="Titre du signataire"
                  name="signatory.title"
                  required
                />
                <FormField
                  type="checkbox"
                  label="L'interlocuteur est différent du signataire ?"
                  name="interlocutor.isActive"
                  required
                />
                <Transition.Group duration={250}>
                  {values.interlocutor && values.interlocutor.isActive ? (
                    <>
                      <FormFieldUi>
                        <Label required>Interlocuteur</Label>
                        <Select
                          onChange={(e, { value }): void => {
                            if (value !== undefined && value !== null) {
                              const selectedOption =
                                proPersonSuggestionOptions?.filter(
                                  (option) => option.value === value,
                                )[0];
                              setFieldValue(
                                'interlocutor.givenName',
                                selectedOption.property.givenName,
                              );
                              setFieldValue(
                                'interlocutor.familyName',
                                selectedOption.property.familyName,
                              );
                              setInterlocutorValue(selectedOption.value);
                            }
                          }}
                          placeholder="Selectionnez l'interlocuteur.."
                          value={interlocutorValue}
                          options={proPersonSuggestionOptions.filter(
                            (option) => option.value !== signatoryValue,
                          )}
                        />
                      </FormFieldUi>
                      <Transition.Group duration={250}>
                        {interlocutorValue === 'custom' ? (
                          <Container fluid style={{ paddingLeft: '50px' }}>
                            <Form.Group widths="equal">
                              <FormField
                                type="text"
                                placeholder="Ex: Nom"
                                label="Nom"
                                name="interlocutor.familyName"
                                required
                              />
                              <FormField
                                type="text"
                                placeholder="Ex: Prénom"
                                label="Prénom"
                                name="interlocutor.givenName"
                                required
                              />
                            </Form.Group>
                          </Container>
                        ) : null}
                      </Transition.Group>
                      <FormField
                        type="text"
                        placeholder="Ex: Resp. commercial"
                        label="Titre de l'interlocuteur"
                        name="interlocutor.title"
                        required
                      />
                      <Divider hidden />
                    </>
                  ) : null}
                </Transition.Group>
                {!isProPublished ? (
                  <FormField
                    name="isStartDateDelayed"
                    type="checkbox"
                    label="Démarrer le contrat ultérieurement ?"
                    helpText={
                      <>
                        Ex: Lancement d&apos;une nouvelle zone; entrée après les
                        congés d&apos;été, etc
                      </>
                    }
                    onChange={() => {
                      setFieldValue('startDate', undefined);
                      setFieldValue(
                        'isStartDateDelayed',
                        !values.isStartDateDelayed,
                      );
                    }}
                  />
                ) : null}

                <Transition.Group duration={250}>
                  {!isProPublished && values.isStartDateDelayed ? (
                    <FormField
                      required
                      name="startDate"
                      type="date"
                      placeholder="Sélectionnez un mois..."
                      label="Date d'entrée en vigueur (au 1er du mois)"
                      values={values}
                      helpText={
                        <>
                          Attention, une fois la date définie, vous ne pourrez
                          plus signer le contrat après cette échéance.
                        </>
                      }
                      showMonthYearPicker
                      dateFormat="MM/yyyy"
                      minDate={
                        new Date(
                          new Date().getFullYear(),
                          new Date().getMonth() + 1,
                          1,
                        )
                      }
                      locale={fr}
                    />
                  ) : null}
                </Transition.Group>

                {!isProPublished && (
                  <FormFieldUi>
                    <Divider hidden />
                    <Divider horizontal style={{ marginTop: '0px' }}>
                      Droit d&apos;entrée
                    </Divider>
                    <Divider hidden />
                    {values.entranceFee?.appliedAmount ? (
                      <Form.Group widths="equal">
                        <FormField
                          type="select"
                          label="Montant"
                          helpText="Montant à appliquer"
                          name="entranceFee.appliedAmount"
                          selection
                          options={entranceFeeAmountOptions}
                        />
                        <FormField
                          type="select"
                          helpText="Étaler le droit d'entrée sur X mois"
                          label="Étalement"
                          selection
                          name="entranceFee.monthSpreadAmount"
                          options={[
                            { key: 1, value: 1, text: 'Ne pas étaler' },
                            { key: 2, value: 2, text: 'Sur 2 mois' },
                            { key: 3, value: 3, text: 'Sur 3 mois' },
                          ]}
                        />
                        <SelectBilikPersonField
                          name="entranceFee.managers"
                          label="Responsable(s)"
                          helpText="Permet le calcul de la prime CA"
                          filters={{
                            proViewId,
                            status: 'published',
                          }}
                          required
                          multiple
                          selection
                          search
                          clearable
                        />
                        <Button
                          style={{
                            marginTop: '42px',
                            minWidth: '45px',
                            height: '37px',
                          }}
                          icon
                          negative
                          type="button"
                          basic
                          onClick={() =>
                            setFieldValue('entranceFee', undefined)
                          }
                        >
                          <Icon name="trash alternate outline" />
                        </Button>
                      </Form.Group>
                    ) : (
                      <Container textAlign="center">
                        <Button
                          style={{ marginBottom: '45px' }}
                          type="button"
                          onClick={async (): Promise<void> => {
                            setFieldValue('entranceFee', {
                              appliedAmount: 500,
                              monthSpreadAmount: 1,
                              managers: [],
                            });
                          }}
                        >
                          <Icon name="plus" />
                          Ajouter un droit d&apos;entrée
                        </Button>
                      </Container>
                    )}
                  </FormFieldUi>
                )}

                <FormFieldUi>
                  <Divider hidden />
                  <Divider horizontal style={{ marginTop: '0px' }}>
                    Abonnements
                  </Divider>
                  <Divider hidden />

                  <Form.Group widths="equal">
                    {isUserAdmin ? (
                      <FormField
                        type="number"
                        label="Mensualité de base"
                        name="subscriptionAmount"
                        required
                        helpText="0€ | 130€ | 160€ | 210€ | 260€ | 315€"
                        placeholder="Renseignez une mensualité..."
                      />
                    ) : (
                      <FormField
                        type="select"
                        name="subscriptionAmount"
                        required
                        selection
                        label="Mensualité de base"
                        helpText="130€ | 160€ | 210€ | 260€ | 315€"
                        placeholder="Sélectionnez une mensualité..."
                        options={[
                          { key: 0, value: 0, text: '0€' },
                          { key: 130, value: 130, text: '130€' },
                          { key: 160, value: 160, text: '160€' },
                          { key: 210, value: 210, text: '210€' },
                          { key: 260, value: 260, text: '260€' },
                          { key: 315, value: 315, text: '315€' },
                        ]}
                      />
                    )}

                    <SelectBilikPersonField
                      name="managers"
                      label="Responsable(s)"
                      helpText="Permet le calcul de la prime CA"
                      filters={{
                        proViewId,
                        status: 'published',
                      }}
                      required
                      multiple
                      selection
                      search
                      clearable
                    />
                  </Form.Group>
                  <div
                    style={{
                      width: '100%',
                      borderBottom: '2px dashed #D2D6DE',
                      margin: '12px 0',
                    }}
                  />
                  <FieldArray
                    name="discounts"
                    render={(arrayHelpers): React.ReactElement => (
                      <Fragment>
                        {values.discounts && values.discounts?.length > 0
                          ? values.discounts?.map((_, index) => (
                              <Fragment key={`discount-${index}`}>
                                <Form.Group widths="equal">
                                  <div
                                    style={{
                                      backgroundColor: '#E8E8E8',
                                      padding: '9px 18px',
                                      borderRadius: '3px',
                                      marginTop: '22px',
                                      marginLeft: '8px',
                                      fontWeight: 'bold',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {index + 1}
                                  </div>
                                  {isUserAdmin ? (
                                    <FormField
                                      type="number"
                                      label="Montant à appliquer"
                                      name={`discounts.${index}.appliedAmount`}
                                      required
                                      placeholder="Renseignez un montant..."
                                    />
                                  ) : (
                                    <FormField
                                      type="select"
                                      name={`discounts.${index}.appliedAmount`}
                                      required
                                      selection
                                      label="Montant à appliquer"
                                      placeholder="Selectionnez un montant..."
                                      options={appliedAmountOptions}
                                    />
                                  )}
                                  <FormField
                                    type="select"
                                    name={`discounts.${index}.occurencesNumber`}
                                    required
                                    selection
                                    label="Durée (en mois)"
                                    placeholder="Sélectionnez la durée en mois..."
                                    options={Array.from(
                                      { length: 12 },
                                      (_, i) => ({
                                        key: i + 1,
                                        value: i + 1,
                                        text: i + 1,
                                      }),
                                    )}
                                  />
                                  <Button
                                    style={{
                                      marginTop: '22px',
                                      minWidth: '45px',
                                      marginRight: '10px',
                                    }}
                                    icon
                                    negative
                                    type="button"
                                    basic
                                    onClick={(): void => {
                                      arrayHelpers.remove(index);
                                    }}
                                  >
                                    <Icon name="trash alternate outline" />
                                  </Button>
                                </Form.Group>
                              </Fragment>
                            ))
                          : null}
                        <Button
                          type="button"
                          disabled={!values.subscriptionAmount} // No discount when 0 or undefined
                          onClick={(): void => {
                            arrayHelpers.push(emptyDiscount);
                          }}
                        >
                          <Icon name="plus" />
                          Ajouter un palier
                        </Button>
                      </Fragment>
                    )}
                  />
                </FormFieldUi>
                <FormField
                  type="textarea"
                  rows={4}
                  resize="vertical"
                  placeholder="Ex : Vous conditionnez une remise au fait que le pro ait deux contrats."
                  label="Conditions particulières"
                  name="specialConditions"
                  helpText="Si rempli : Un email sera envoyé à Mica pour validation"
                />
              </Form>
            )}
            {step === 2 && (
              <>
                <Table celled basic="very" selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Date de facturation</Table.HeaderCell>
                      <Table.HeaderCell>Nom</Table.HeaderCell>
                      <Table.HeaderCell>Montant</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {subscriptionPreviewRows.map((subscription, index) => (
                      <Table.Row key={`subscription-row-${index}`}>
                        <Table.Cell>{subscription.date}</Table.Cell>
                        <Table.Cell>{subscription.text}</Table.Cell>
                        <Table.Cell>{subscription.amount}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button type="submit" onClick={handleModalClose}>
              Fermer
            </Button>
            {step === 1 && (
              <Button
                type="submit"
                primary
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={() => {
                  setSubscriptionPreviewRows([]);
                  buildPreviewEntranceFeeRows(values);
                  buildPreviewSubscriptionsRows(values);
                  setStep(2);
                }}
              >
                Suivant
              </Button>
            )}
            {step === 2 && (
              <>
                <Button type="button" onClick={() => setStep(1)}>
                  Retour
                </Button>

                <Button
                  type="submit"
                  primary
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  onClick={async () => {
                    await submitForm();
                    setStep(1);
                  }}
                >
                  <Icon name="save" />
                  Générer
                </Button>
              </>
            )}
          </Modal.Actions>
        </>
      </Modal>
    </>
  );
};

export default ProContractFormView;
