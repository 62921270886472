import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Loader, Message } from 'semantic-ui-react';
import { SolicitationsTableQuery } from 'generated/graphql';
import SolicitationsTableView from './solicitations-table-view';
import { Unpacked } from 'utils/unpacked';
import env from 'env';

type TableContainerProps = {
  orderBy: {
    column: string;
    direction: string;
  };
  globallyBlockedEntities: {
    identifier: string;
  }[];
  refetch: () => void;
  onSort: (column: string, direction: string) => void;
  onFilter: (column: string, value: string) => void;
  solicitations: SolicitationsTableQuery['solicitation'];
  isInProViewForm: boolean;
  resendMailToPro: (uuid: string) => Promise<void>;
};

const SolicitationsTableContainer: FunctionComponent<TableContainerProps> = ({
  orderBy,
  solicitations,
  isInProViewForm,
  globallyBlockedEntities,
  refetch,
  onFilter,
  onSort,
  resendMailToPro,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchSolicitations = async (): Promise<void> => {
      setLoading(true);
      await refetch();
      setLoading(false);
    };
    fetchSolicitations();
  }, [refetch]);

  const onClickSortByCreateDate = useCallback((): void => {
    switch (orderBy.direction) {
      case 'ascending': {
        onSort('dateCreated', 'descending');
        break;
      }
      case 'descending': {
        onSort('dateCreated', 'ascending');
        break;
      }
    }
  }, [orderBy.direction]);

  const onClickEmail = useCallback(
    (email?: string): void => {
      if (email) {
        onFilter('privateIndividualEmail', email);
      }
    },
    [onFilter],
  );

  const onClickIp = useCallback(
    (ip?: string): void => {
      if (ip) {
        onFilter('ip', ip);
      }
    },
    [onFilter],
  );

  const onClickProPresentationId = useCallback(
    (proPresentationId?: number): void => {
      if (proPresentationId) {
        onFilter('proPresentationId', String(proPresentationId));
      }
    },
    [onFilter],
  );

  const onClickOpenProReview = useCallback(
    (solicitation?: Unpacked<SolicitationsTableQuery['solicitation']>) => {
      window.open(
        `${env.SITE_URL}/presentations/${solicitation?.proView.id}-${solicitation?.proView.proPresentation.slug}#reviews`,
        '_blank',
      );
    },
    [],
  );

  if (!loading && !solicitations.length) {
    return <Message>Il n&apos;y a pas de résultats</Message>;
  }

  if (loading && !solicitations.length) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  return (
    <SolicitationsTableView
      refetch={refetch}
      loading={loading}
      onClickEmail={onClickEmail}
      onClickIp={onClickIp}
      onClickOpenProReview={onClickOpenProReview}
      onClickProPresentationId={onClickProPresentationId}
      onClickSortByCreateDate={onClickSortByCreateDate}
      orderBy={orderBy}
      solicitations={solicitations}
      isInProViewForm={isInProViewForm}
      globallyBlockedEntities={globallyBlockedEntities}
      resendMailToPro={resendMailToPro}
    />
  );
};

export default SolicitationsTableContainer;
