import { PrivateIndividualSearch } from 'components/private-individual-search-filter';
import { ProReviewInvitationsEmailsForm } from 'components/pro-review-invitation-emails-form';
import { ProReviewInvitationsTable } from 'components/pro-review-invitations-table';
import { ProReviewInvitationBoolExp } from 'generated/graphql';
import { useProReviewInvitationsCount } from 'hooks/use-pro-review-invitations-count/use-pro-review-invitations-count';
import React, { FunctionComponent, useMemo } from 'react';
import {
  Button,
  Container,
  Icon,
  Label,
  Message,
  Modal,
  Pagination,
} from 'semantic-ui-react';
import {
  BooleanParam,
  NumberParam,
  ObjectParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';

type ProReviewInvitationsRouteProps = {
  proPresentationId?: number;
  proViewId?: number;
};

const ProReviewInvitationsRoute: FunctionComponent<
  ProReviewInvitationsRouteProps
> = ({ proPresentationId, proViewId }) => {
  const itemPerPage = useMemo(() => 20, []);
  const [open, setOpen] = React.useState(false);
  const [filters, setFilters] = useQueryParams({
    hasProReview: BooleanParam,
    isAutorespondActive: BooleanParam,
    proReviewInvitationId: NumberParam,
    email: StringParam,
    isAllZones: BooleanParam,
    page: NumberParam,
    orderBy: ObjectParam,
  });

  const queryFilters = useMemo<ProReviewInvitationBoolExp>(
    () => ({
      id: { _eq: filters.proReviewInvitationId ?? undefined },
      email: { _eq: filters.email ?? undefined },
      proPresentationId: { _eq: proPresentationId },
    }),
    [filters],
  );
  const { count: allCount, loading: allLoading } =
    useProReviewInvitationsCount(queryFilters);

  const { count: hasProReviewCount, loading: hasProReviewLoading } =
    useProReviewInvitationsCount({
      ...queryFilters,
      proReviewId: {
        _isNull: false,
      },
    });

  const {
    count: isAutorespondActiveCount,
    loading: isAutorespondActiveLoading,
  } = useProReviewInvitationsCount({
    ...queryFilters,
    isAutorespondActive: { _eq: true },
  });

  const {
    count: isAutorespondInactiveCount,
    loading: isAutorespondInactiveLoading,
  } = useProReviewInvitationsCount({
    ...queryFilters,
    isAutorespondActive: { _eq: false },
  });

  const { count } = useProReviewInvitationsCount({
    ...queryFilters,
    email: { _eq: filters.email ?? undefined },
    isAutorespondActive: { _eq: filters.isAutorespondActive ?? undefined },
    proReviewId: {
      _isNull:
        filters.hasProReview !== undefined && filters.hasProReview !== null
          ? !filters.hasProReview
          : undefined,
    },
  });

  const totalPages = useMemo<number>(
    () => Math.ceil((count || 0) / itemPerPage),
    [count],
  );

  if (!proPresentationId) {
    return (
      <Message>
        No proViewId provided, are we in create/create-zone mode ? this message
        should never be displayed, because the component should never be
        rendered in this case
      </Message>
    );
  }

  return (
    <Container fluid style={{ marginTop: '14px' }}>
      <Button.Group>
        <Button
          type="button"
          color="grey"
          loading={allLoading}
          disabled={allLoading}
          basic={
            filters.isAutorespondActive !== undefined ||
            filters.hasProReview !== undefined
          }
          onClick={(): void => {
            setFilters({
              hasProReview: undefined,
              isAutorespondActive: undefined,
              page: 1,
            });
          }}
        >
          Tous ({allCount})
        </Button>
        <Button
          type="button"
          color="green"
          loading={hasProReviewLoading}
          disabled={hasProReviewLoading}
          basic={!filters.hasProReview}
          onClick={(): void => {
            setFilters({
              isAutorespondActive: undefined,
              hasProReview: true,
              page: 1,
            });
          }}
        >
          Avis réalisés ({hasProReviewCount})
        </Button>
        <Button
          type="button"
          color="red"
          loading={isAutorespondActiveLoading}
          disabled={isAutorespondActiveLoading}
          basic={!filters.isAutorespondActive}
          onClick={(): void => {
            setFilters({
              hasProReview: undefined,
              isAutorespondActive: true,
              page: 1,
            });
          }}
        >
          Relances actives ({isAutorespondActiveCount})
        </Button>
        <Button
          type="button"
          color="grey"
          loading={isAutorespondInactiveLoading}
          disabled={isAutorespondInactiveLoading}
          basic={
            filters.isAutorespondActive === undefined ||
            !!filters.isAutorespondActive
          }
          onClick={(): void => {
            setFilters({
              hasProReview: undefined,
              isAutorespondActive: false,
              page: 1,
            });
          }}
        >
          Relances inactives ({isAutorespondInactiveCount})
        </Button>
      </Button.Group>

      <Button
        style={{ marginLeft: '12px' }}
        type="button"
        onClick={(): void => {
          setOpen(true);
        }}
      >
        <Icon name="edit" />
        Personnaliser
      </Button>

      {filters.email ? (
        <Button
          as="div"
          style={{ marginLeft: '12px', marginRight: '0' }}
          labelPosition="right"
        >
          <Button
            color="blue"
            onClick={(): void => {
              setFilters({ email: undefined, page: 1 });
            }}
          >
            <Icon name="delete" />
          </Button>
          <Label basic pointing="left">
            {filters.email}
          </Label>
        </Button>
      ) : (
        <PrivateIndividualSearch
          onResultSelect={(event, { result }): void => {
            setFilters({ email: result.value, page: 1 });
          }}
          searchContext="proReviewInvitation"
          proPresentationId={proPresentationId}
        />
      )}

      {filters.proReviewInvitationId ? (
        <Button
          as="div"
          style={{ marginLeft: '12px', marginRight: '0' }}
          labelPosition="right"
        >
          <Button
            color="blue"
            onClick={(): void => {
              setFilters({ proReviewInvitationId: undefined });
            }}
          >
            <Icon name="delete" />
          </Button>
          <Label basic pointing="left">
            {`Demande d'avis ${filters.proReviewInvitationId}`}
          </Label>
        </Button>
      ) : null}

      <ProReviewInvitationsTable
        filters={{
          proViewId,
          proReviewInvitationId: filters.proReviewInvitationId ?? undefined,
          hasProReview: filters.hasProReview ?? undefined,
          isAutorespondActive: filters.isAutorespondActive ?? undefined,
          email: filters.email ?? undefined,
        }}
        orderBy={{
          column: filters.orderBy?.column ?? 'dateCreated',
          direction: filters.orderBy?.direction ?? 'descending',
        }}
        onSort={(column, direction): void => {
          setFilters({
            orderBy: { column, direction },
          });
        }}
        onFilter={(column, value): void => {
          setFilters({
            [column]: value,
          });
        }}
        limit={itemPerPage}
        offset={filters.page ? (filters.page - 1) * itemPerPage : 0}
      />
      {totalPages > 1 ? (
        <Container fluid textAlign="right" style={{ marginTop: '15px' }}>
          <Pagination
            activePage={filters.page ?? 1}
            totalPages={totalPages}
            onPageChange={(event, { activePage }): void => {
              setFilters({
                page: Number(activePage),
              });
            }}
            ellipsisItem={{
              content: <Icon name="ellipsis horizontal" />,
              icon: true,
            }}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
          />
        </Container>
      ) : null}
      <Modal
        open={open}
        onClose={(): void => {
          setOpen(false);
        }}
        closeIcon
      >
        <Modal.Header>Personnalisation de la demande d&apos;avis</Modal.Header>
        <Modal.Content>
          <ProReviewInvitationsEmailsForm
            proPresentationId={proPresentationId}
            onSuccess={() => {
              setOpen(false);
            }}
          />
        </Modal.Content>
      </Modal>
    </Container>
  );
};

export default ProReviewInvitationsRoute;
