import { apiClient } from 'axios-client';
import env from 'env';
import {
  ProPresentationSetInput,
  useProReviewInvitationMailsMutation,
} from 'generated/graphql';
import React, { FunctionComponent } from 'react';
import ProReviewInvitationsEmailsFormContainer from './pro-review-invitation-emails-form-container';

type ProReviewInvitationsEmailsFormRepository = {
  proPresentationId: number;
  onSuccess?: () => void;
};

const ProReviewInvitationsEmailsFormRepository: FunctionComponent<
  ProReviewInvitationsEmailsFormRepository
> = ({ proPresentationId, onSuccess }) => {
  const fetchEmails = async (forceDefault = false) => {
    const firstEmail = await apiClient.post(
      `${env.API_URL}/form/review-invitation/email-pi-template`,
      {
        proPresentationId,
        forceDefault,
      },
    );

    const dunningEmail = await apiClient.post(
      `${env.API_URL}/form/review-invitation/email-pi-template`,
      {
        proPresentationId,
        forceDefault,
        isDunned: true,
      },
    );

    return {
      firstEmail: firstEmail.data.content,
      dunningEmail: dunningEmail.data.content,
    };
  };

  const [updateProReviewInvitationEmailsMutation] =
    useProReviewInvitationMailsMutation();

  const updateProReviewInvitationEmails = async (
    id: number,
    proPresentation: ProPresentationSetInput,
  ) => {
    await updateProReviewInvitationEmailsMutation({
      variables: {
        id,
        proPresentation,
      },
    });
  };

  return (
    <ProReviewInvitationsEmailsFormContainer
      proPresentationId={proPresentationId}
      fetchEmails={fetchEmails}
      updateProReviewInvitationEmails={updateProReviewInvitationEmails}
      onSuccess={onSuccess}
    />
  );
};

export default ProReviewInvitationsEmailsFormRepository;
